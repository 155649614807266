// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  date_picker_options: {
    dateFormat: 'dd/mm/yyyy',
    minYear: 1900,
    maxYear: 2100
  },
  postalCode: {
    en: /[A-Z]{1,2}[0-9]{1,2}[A-Z]?\s?[0-9][A-Z]{2}/,
    es: /^\d{5}?$/,
    de: /^\d{4}?$/,
    'de-ch': /^\d{4}?$/,
    ca: /^\d{5}?$/,
    'pt-br': /^\d{5}?$/,
    fr: /^\d{5}?$/,
    it: /^\d{5}?$/,
  },
  coin: {
    'es': '€',
    'en': '£',
    'de': '€',
    'de-ch': 'Fr.',
    'ca': '€',
    'pt-br': '€',
    'fr': '€',
    'it': '€',
    'tech': '€'
  },
  calendar: {
    en: {
      firstDayOfWeek: 1,
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [ 'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December' ],
      monthNamesShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk'
    },
    es: {
      firstDayOfWeek: 1,
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      monthNames: [ 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre' ],
      monthNamesShort: [ 'ene', 'fer', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic' ],
      today: 'Hoy',
      clear: 'Limpiar',
      dateFormat: 'dd/mm/yyyy',
      weekHeader: 'Sem'
    },
    de: {
      firstDayOfWeek: 1,
      dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
      'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
      monthNamesShort: [ 'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
      today: 'Heute',
      clear: 'Klar',
      dateFormat: 'dd.mm.yyyy',
      weekHeader: 'Wo'
  },
    'de-ch': {
      firstDayOfWeek: 1,
      dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
      'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
      monthNamesShort: [ 'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
      today: 'Heute',
      clear: 'Klar',
      dateFormat: 'dd.mm.yyyy',
      weekHeader: 'Wo'
  },
    ca: {
      firstDayOfWeek: 1,
      dayNames: ['Diumenge', 'Dilluns', 'Dimarts', 'Dimecres', 'Dijous', 'Divendres', 'Dissabte '],
      dayNamesShort: ['Diu', 'Dil', 'Dim', 'Dime', 'Dij', 'Div', 'Dis'],
      dayNamesMin: ['Dg', 'Dl', 'Dt', 'Dc', 'Dj', 'Dv', 'Ds'],
      monthNames: [ 'gener', 'febrer', 'març', 'abril', 'maig', 'juny', 'juliol', 'agost',
      'setembre', 'octubre', 'novembre', 'desembre' ],
      monthNamesShort: [ 'gen', 'feb', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'oct', 'nov', 'des' ],
      today: 'Avui',
      clear: 'Netejar',
      dateFormat: 'dd/mm/yyyy',
      weekHeader: 'Set'
  },
    'pt-br': {
      firstDayOfWeek: 0,
      dayNames: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'],
      dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb'],
      dayNamesMin: ['D','S','T','Q','Q','S','S'],
      monthNames: [ 'Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
      monthNamesShort: [ 'Jan','Fev','Mar','Abr','Mai','Jun', 'Jul','Ago','Set','Out','Nov','Dez'],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yyyy',
      weekHeader: 'Sem'
  },
  fr: {
    firstDayOfWeek: 1,
    dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: [ 'gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre' ],
    monthNamesShort: [ 'gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],
    today: 'Aujourd\'hui',
    clear: 'Nettoyer',
    dateFormat: 'dd/mm/yyyy',
    weekHeader: 'Sem'
  },
  it: {
    firstDayOfWeek: 1,
    dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
    monthNamesShort: [ 'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Oggi',
    clear: 'Ripulire',
    dateFormat: 'dd/mm/yyyy',
    weekHeader: 'Set'
  },
  tech: {
    firstDayOfWeek: 1,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [ 'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December' ],
    monthNamesShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'mm/dd/yy',
    weekHeader: 'Wk'
  }
  },
  queries: {
  es: {
    dateFormat: 'dd/MM/yyyy',
    dateFullFormat: 'dd/MM/yyyy HH:mm',
    timeFormat: 'HH:mm'
  },
  en: {
    dateFormat: 'MM/dd/yyyy',
    dateFullFormat: 'MM/dd/yyyy HH:mm',
    timeFormat: 'HH:mm'
  },
  de: {
    dateFormat: 'dd.MM.yyyy',
    dateFullFormat: 'dd.MM.yyyy HH:mm',
    timeFormat: 'HH:mm'
  },
  'de-ch': {
    dateFormat: 'dd.MM.yyyy',
    dateFullFormat: 'dd.MM.yyyy HH:mm',
    timeFormat: 'HH:mm'
  },
  ca: {
    dateFormat: 'dd/MM/yyyy',
    dateFullFormat: 'dd/MM/yyyy HH:mm',
    timeFormat: 'HH:mm'
  },
  fr: {
    dateFormat: 'dd/MM/yyyy',
    dateFullFormat: 'dd/MM/yyyy HH:mm',
    timeFormat: 'HH:mm'
  },
  'pt-br': {
    dateFormat: 'dd/MM/yyyy',
    dateFullFormat: 'dd/MM/yyyy HH:mm',
    timeFormat: 'HH:mm'
  },
  it: {
    dateFormat: 'dd/MM/yyyy',
    dateFullFormat: 'dd/MM/yyyy HH:mm',
    timeFormat: 'HH:mm'
  }
  },
  module: 'CRM',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { MasterReport, MasterReportAbstractFactory } from "@madrija/base-ui/common";
import { OperationMasterReportDTOHelper, FollowUpMasterReportDTOHelper as CrmFollowUpMasterReportDTOHelper } from "@madrija/crm-ui/common";
import { ProcedureMasterReportDTOHelper as SihProcedureMasterReportDTOHelper, FollowUpMasterReportDTOHelper as SihFollowUpMasterReportDTOHelper } from "@madrija/sih-ui/common";
import { PatientHistoryHelper } from "@madrija/medical-ui/common";
import { ProcedureMasterReportDTOHelper as EpProcedureMasterReportDTOHelper } from "@madrija/electro-ui/common";
import { EcoCardioMasterReportDTOHelper, TacCardioMasterReportDTOHelper, RmCardioMasterReportDTOHelper } from "@madrija/cardio-image-ui/common";
import { ProcedureMasterReportDTOHelper as VasProcedureMasterReportDTOHelper, FollowupMasterReportDTOHelper as VasFollowUpMasterReportDTOHelper } from "@madrija/vascular-ui/common";
import { CaseClosureVisitMasterReportDTOHelper as CrCaseClosureVisitMasterReportDTOHelper, FirstVisitMasterReportDTOHelper as CrFirstVisitMasterReportDTOHelper, FollowUpMasterReportDTOHelper as CrFollowUpMasterReportDTOHelper, NutritionistVisitMasterReportDTOHelper as CrNutritionistVisitMasterReportDTOHelper, PharmacyMasterReportDTOHelper as CrPharmacyMasterReportDTOHelper, PreProgramMasterReportDTOHelper as CrPreProgramMasterReportDTOHelper, PsychologistVisitMasterReportDTOHelper as CrPsychologistVisitMasterReportDTOHelper, TrainingSessiontMasterReportDTOHelper as CrTrainingSessionMasterReportDTOHelper } from "@madrija/cardiorehab-ui/common";

export class MasterReportFactory extends MasterReportAbstractFactory {

    static categories = ['CrmOperation', 'CrmFollowUp', 'MedPatient', 'CimEcoCardio', 'CimTACCardio', 'CimRMCardio', 'SihProcedure', 'SihFollowUp', 'EpProcedure', 'VasProcedure', 'VasFollowUp', 'CrCaseClosureVisit', 'CrFirstVisit', 'CrFollowUp', 'CrNutritionistVisit', 'CrPharmacy', 'CrPreProgram', 'CrPsychologistVisit', 'CrTrainingSession'];

    getAvailableCategories(): string[] {
        return MasterReportFactory.categories;
    }

    override getInstance(category: string, object: any): MasterReport {
        switch (category) {
            case MasterReportFactory.categories[0]:
                return OperationMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[1]:
                return CrmFollowUpMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[2]:
                return PatientHistoryHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[3]:
                return EcoCardioMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[4]:
                return TacCardioMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[5]:
                return RmCardioMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[6]:
                return SihProcedureMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[7]:
                return SihFollowUpMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[8]:
                return EpProcedureMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[9]:
                return VasProcedureMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[10]:
                return VasFollowUpMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[11]:
                return CrCaseClosureVisitMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[12]:
                return CrFirstVisitMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[13]:
                return CrFollowUpMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[14]:
                return CrNutritionistVisitMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[15]:
                return CrPharmacyMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[16]:
                return CrPreProgramMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[17]:
                return CrPsychologistVisitMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            case MasterReportFactory.categories[18]:
                return CrTrainingSessionMasterReportDTOHelper.buildFromJSON(object.context?.master_report) ?? EmptyMasterReport;
            default:
                return new EmptyMasterReport();
        }
    }
}

export class EmptyMasterReport implements MasterReport { }

import { Injectable, Injector } from '@angular/core';
import { TimelineElementAbstractFactory, MedicalTimelineElement, SurveyTimelineElement, LostTransmissionTimelineElement, DiagnosticProcedureTimelineElement, IotMeasurementTimelineElement, OrderStatus, PatientHistoryHelper } from '@madrija/medical-ui/common';
import { Router } from '@angular/router';
import { FaDebutTimelineElement, FollowUpTimelineElement, OperationTimelineElement } from '@madrija/crm-ui/common';
import { ProcedureTimelineElement as EpProcedureTimelineElement } from '@madrija/electro-ui/common';
import { ProcedureTimelineElement as SihProcedureTimelineElement, FollowUpTimelineElement as SihFollowUpTimelineElement } from '@madrija/sih-ui/common';
import { EcgTimelineElement as EcgTestTimelineElement } from '@madrija/ecg-ui/common';
import { EcoCardioTimelineElement, TacTimelineElement, RmCardioTimelineElement } from '@madrija/cardio-image-ui/common';
import { ProcedureTimelineElement as VasProcedureTimelineElement, FollowUpTimelineElement as VasFollowUpTimelineElement } from '@madrija/vascular-ui/common';
import { CvisOrderHistoryDTO } from '../models/cvisOrderHistoryDTO.model';
import { CaseClosureVisitTimelineElement, FirstVisitTimelineElement, TrainingSessionTimelineElement, PsychologistVisitTimelineElement, NutritionistVisitTimelineElement, FollowUpTimelineElement as CrFollowUpTimelineElement, PreProgramTimelineElement, PharmacyTimelineElement } from '@madrija/cardiorehab-ui/common';
@Injectable()
export class CvisTimelineElementFactory extends TimelineElementAbstractFactory {
  public getInstance(type: string, data: CvisOrderHistoryDTO, tooltip: { data: CvisOrderHistoryDTO, injector: Injector }, selected: number): MedicalTimelineElement {
    let clazz = '';
    let value: MedicalTimelineElement = new MedicalTimelineElement(0, new Date(), '', null);
    const router: Router = tooltip.injector.get(Router);
    const actualURL = window.location.pathname;
    switch (type) {
      case 'CrmOperation':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'madrija-crm') ? 'closed' : '';
        value = new OperationTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz.concat((data.importType === 'REMOTE') ? ' remote' : ''), data.orderId === selected, () => {
            const actualCrmUrl = window.location.pathname;
            const route = actualCrmUrl.replace(/\/([\w-]+\/)+\d+\//gm, `views/crm/operation/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/crm.svg', { icon: 'f7b9', iconColor: '#ffc83f' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');

        break;
      case 'CrmFollowUp':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'madrija-crm') ? 'closed' : '';
        value = new FollowUpTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz.concat((data.importType === 'REMOTE') ? ' remote' : ''), data.orderId === selected, () => {
            const actualCrmUrl = window.location.pathname;
            const route = actualCrmUrl.replace(/\/([\w-]+\/)+\d+\//gm, `views/crm/follow/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/crm.svg', { icon: 'f0f1', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'EpProcedure':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'madrija-electro') ? 'closed' : '';
        value = new EpProcedureTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected, () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/ep/procedure/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/ep.svg', { icon: 'f7b9', iconColor: '#ffc83f' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'SihProcedure':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'madrija-sih') ? 'closed' : '';
        value = new SihProcedureTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected, () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/sih/procedure/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/sih.svg', { icon: 'f7b9', iconColor: '#ffc83f' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'SihFollowUp':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'madrija-sih') ? 'closed' : '';
        value = new SihFollowUpTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected, () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/sih/followup/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/sih.svg', { icon: 'f0f1', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CimEcoCardio':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardio-image') ? 'closed' : '';
        value = new EcoCardioTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected, () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cim/ecocardio/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cim.svg', { imageSrc: 'assets/images/order-types/eco.svg', iconColor: '#6ba5d7' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CimTACCardio':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardio-image') ? 'closed' : '';
        value = new TacTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cim/tac/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cim.svg', { imageSrc: 'assets/images/order-types/tac.svg', iconColor: '#6ba5d7' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CimRMCardio':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardio-image') ? 'closed' : '';
        value = new RmCardioTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cim/rm/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cim.svg', { imageSrc: 'assets/images/order-types/reso.svg', iconColor: '#6ba5d7' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'EcgTest':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-ecg') ? 'closed' : '';
        value = new EcgTestTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected, () => {
            const actualURL = window.location.pathname;
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/ecg/test/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/ecg.svg', { icon: 'f0f1', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CrFirstVisit':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardiorehab') ? 'closed' : '';
        value = new FirstVisitTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cardiorehab/firstvisit/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cr.svg', { icon: 'e50d', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CrFollowUp':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardiorehab') ? 'closed' : '';
        value = new CrFollowUpTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cardiorehab/followup/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cr.svg', { icon: 'f0f1', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CrCaseClosureVisit':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardiorehab') ? 'closed' : '';
        value = new CaseClosureVisitTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cardiorehab/case-closure-visit/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cr.svg', { icon: 'f304', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CrPsychologistVisit':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardiorehab') ? 'closed' : '';
        value = new PsychologistVisitTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cardiorehab/psychologistvisit/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cr.svg', { icon: 'f086', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CrNutritionistVisit':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardiorehab') ? 'closed' : '';
        value = new NutritionistVisitTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cardiorehab/nutritionistvisit/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cr.svg', { icon: 'e2cd', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CrPharmacy':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardiorehab') ? 'closed' : '';
        value = new PharmacyTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cardiorehab/pharmacy/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cr.svg', { icon: 'f485', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CrTrainingSession':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardiorehab') ? 'closed' : '';
        value = new TrainingSessionTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cardiorehab/trainingsession/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cr.svg', { icon: 'f2f2', iconColor: '#e69138' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'CrPreProgram':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'enigma-cardiorehab') ? 'closed' : '';
        value = new PreProgramTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected,
          () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/cardiorehab/pre-program/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/cr.svg', { icon: 'f304', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'VasProcedure':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'madrija-vascular') ? 'closed' : '';
        value = new VasProcedureTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected, () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/vascular/procedure/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/vascular.svg', { icon: 'f7b9', iconColor: '#ffc83f' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'VasFollowUp':
        clazz = PatientHistoryHelper.isClosed(data.appointmentHistoryDTO!.patientRecordModulesCloseDates, data.appointmentHistoryDTO!.start!, 'madrija-vascular') ? 'closed' : '';
        value = new VasFollowUpTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, clazz, data.orderId === selected, () => {
            const route = actualURL.replace(/\/([\w-]+\/)+\d+\//gm, `views/vascular/follow/${data.orderId}/`);
            router.navigate([route]);
          }, 'assets/images/modules/vascular.svg', { icon: 'f0f1', iconColor: '#16c98d' }, !clazz.includes('closed') ? CvisTimelineElementFactory.extraIcon(data.orderStatus) : '');
        break;
      case 'FA_DEBUT':
        value = new FaDebutTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected);
        break;
      case 'SURVEY':
        value = new SurveyTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected);
        break;
      case 'LOST_TRANSMISSION':
        value = new LostTransmissionTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected, undefined, 'assets/images/lost-transmission.svg');
        break;
      case 'DIAGNOSTIC_PROCEDURE':
        value = new DiagnosticProcedureTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected);
        break;
      case 'IOT_MEASUREMENT':
        value = new IotMeasurementTimelineElement(data.orderId!, data.appointmentHistoryDTO!.start!,
          data.dtype!, tooltip, true, data.orderStatus === 'CANCELED' ? 'canceled' : 'timeline-element', data.orderId === selected);
        break;

      default:
        value.type = data.dtype ?? '';
        break;
    }
    return value;
  }

  static extraIcon(orderStatus: OrderStatus | null): string {
    if (orderStatus === OrderStatus.CANCELED) {
      return 'canceled-icon'
    } else if (orderStatus !== OrderStatus.FINALIZED) {
      return 'fas fa-exclamation-triangle text-warning'
    }
    return '';
  }
}


<mab-card id="card" [title]="'WaitingList.management' | translate" [classTitle]="''"
  [cardClass]="'col-sm-12 col-md-12 offset-lg-1 col-xl-10 col-lg-10 mt-2 mb-3 border'" style="display: block;">
  <div class="row">
    <ng-container #moduleTypeTemplate>
      <div class="form-group">
        <mab-form-control [level]="1" [formGroup]="moduleForm" [isRequired]="true"
          [formId]="'CVIS.appointments.appointments_form.module'" [labelValue]="'Appointment.module' | rootTranslate"
          [columns]="''" [clazz]="'row mb-4 pull-left'"
          [validationRequiredError]="'COMMON.validationRequiredError' | rootTranslate"
          [validationRegexError]="'COMMON.validationRegexError' | rootTranslate">
          <ng-container *ngFor="let module of cvisModules">
            <div *ngIf="hasModule(module.toLowerCase())" class="col-6 col-sm-3 col-xxl-2 pe-0">
              <div class="form-check ps-0 py-1">
                <input type="radio" class="form-check-input-custom" [id]="module" [value]="module"
                  formControlName="module" name="module">
                <label class="form-check-label form-check-label-custom py-3" [for]="module">{{'MODULE.'+ module |
                  rootTranslate}}
                  <img [src]="'assets/images/modules/' + module.toLowerCase() + '.svg'" width="20" height="20"></label>
              </div>
            </div>
          </ng-container>
        </mab-form-control>
      </div>
    </ng-container>
  </div>

  <div class="row pt-1" *ngIf="isModuleCheck('Crm')">
    <mmui-waiting-list [orderTypes]="getOrderTypesFromModule('Crm')" [extraFields]="['device']"></mmui-waiting-list>
  </div>
  <div class="row pt-1" *ngIf="isModuleCheck('Sih')">
    <msihui-sih-waiting-list [orderTypes]="getOrderTypesFromModule('Sih')"></msihui-sih-waiting-list>
  </div>
  <div class="row pt-1" *ngIf="isModuleCheck('Cim')">
    <mmui-waiting-list [orderTypes]="getOrderTypesFromModule('Cim')"></mmui-waiting-list>
  </div>
  <div class="row pt-1" *ngIf="isModuleCheck('Ecg')">
    <mmui-waiting-list [orderTypes]="getOrderTypesFromModule('Ecg')"></mmui-waiting-list>
  </div>
  <div class="row pt-1" *ngIf="isModuleCheck('Ep')">
    <mmui-waiting-list [orderTypes]="getOrderTypesFromModule('Ep')"></mmui-waiting-list>
  </div>
  <div class="row pt-1" *ngIf="isModuleCheck('Uc')">
    <mmui-waiting-list [orderTypes]="getOrderTypesFromModule('Uc')"></mmui-waiting-list>
  </div>
  <div class="row pt-1" *ngIf="isModuleCheck('Insufi')">
    <mmui-waiting-list [orderTypes]="getOrderTypesFromModule('Insufi')"></mmui-waiting-list>
  </div>
  <div class="row pt-1" *ngIf="isModuleCheck('Cr')">
    <mmui-waiting-list [orderTypes]="getOrderTypesFromModule('Cr')"></mmui-waiting-list>
  </div>
  <div class="row pt-1" *ngIf="isModuleCheck('Vascular')">
    <mmui-waiting-list [orderTypes]="getOrderTypesFromModule('Vas')"></mmui-waiting-list>
  </div>
</mab-card>
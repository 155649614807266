
import { DatePipe } from '@angular/common';
import { TooltipEvent } from '@madrija/angular-base/common';
import { VendorService } from '@madrija/materials-ui/common';
import { TranslateService } from '@ngx-translate/core';
import { AlertPatient, ImportType } from '@madrija/crm-ui/common';
import { CvisAppointment } from './cvis-appointment.model';
import { Order } from '@madrija/medical-ui/common';


export class Tooltip extends TooltipEvent {
  override eventTs: CvisAppointment | null = null;
  constructor(private translateService: TranslateService, private vendorService: VendorService) {
    super();

  }
  person(): string {
    return (this.eventTs && this.eventTs.patient) ? this.eventTs.patient.toString() : '';
  }
  time(): string {
    const dp = new DatePipe('en');
    const result = (this.eventTs && this.eventTs.start) ? dp.transform(this.eventTs.start, 'HH:mm') : '';
    return (result) ? result : '';
  }
  extraInfo(): string {
    return this.importHTML() + this.orderHTML() + this.roomHTML() + this.scheduleHTML() + this.originHTML() + this.finalizedHTML() + this.operationTypeHTML()
      + this.deviceCameralTypeHTML() + this.modelHTML() + this.manufacturer() + this.deviceTypeHTML() + this.serialNumberHTML() + this.conflictHTML()
      + this.canceledHTML() + this.patientStatusInOrderHTML();
  }
  extraInTitle(): string {
    return this.orderSvg();
  }

  override mainInfo = () => {
    const condition = localStorage.getItem('MED_SHOW_APPOOINTMENTREASONS_TOOLTIP') === 'true';
    if (!condition) return '';
    return this.eventTs?.appointmentReasons ? `<div class="main-info pt-1 mb-1 border-bottom row"><div class="col-2"><i class="fa-solid fa-clipboard-user fa-xl"></i></div><p class="col-10 mb-0">${this.eventTs?.appointmentReasons?.code ?? ''} - ${this.eventTs?.appointmentReasons?.text ?? ''}</p></div>` : '';
  }

  override finalInfo = () => {
    const otherComments = this.eventTs?.getOtherComments() ?? '';
    return otherComments ? `<div class="final-info pt-1 mb-1 row mx-0"><div class="col-1"><i class="fa-solid fa-comment fa-lg"></i></div><p class="col-11 mb-0 comment">${otherComments}</p></div>` : '';
  }

  private importHTML(): string {
    let result = '';
    if (this.eventTs && this.eventTs.isImported()) {
      result = '<li>' +
        '<span class="tooltext me-2">' +
        this.importIcon() + ''
        + this.import() + '</span>' +
        '</li>';
    }
    return result;
  }

  private orderHTML(): string {
    let result = '';
    if (this.eventTs && this.eventTs.getOrderType()) {
      result = '<li>' +
        '<span class="tooltext me-2">' +
        this.orderIcon() + ''
        + this.order() + '</span>' +
        '</li>';
    }
    return result;
  }

  private roomHTML(): string {
    let result = '';
    if (this.eventTs?.checkClinicalEvent()) {
      result = '<li>' +
        '<span class="tooltext"><i class="fa fa-square me-2" style="color: ' + (this.eventTs ? this.eventTs.getRoomColor() : '') + '"></i>' + this.room() + '</span>' +
        '</li>';
    }
    return result;
  }

  private scheduleHTML(): string {
    let result = '';
    if (this.eventTs?.checkClinicalEvent()) {
      result = '<li>' +
        '<span class="tooltext"><i class="fa fa-square me-2" style="color: ' + (this.eventTs ? this.eventTs.getScheduleColor() : '') + '"></i>' + this.schedule() + '</span>' +
        '</li>';
    }
    return result;
  }

  private modelHTML(): string {
    let result = ''
    let translate = this.eventTs ? this.eventTs.modelType(this.translateService.currentLang) : '';
    if (translate) {
      result = '<li>' +
        '<span class="tooltext"><i class="fa fa-project-diagram me-2" ></i>' + translate + '</span>' +
        '</li>';
    }
    return result
  }
  private manufacturer(): string {
    let result = '';
    let translate = this.eventTs?.manufacturer() ?? '';
    let alertpatient = new AlertPatient()
    if (this.eventTs) alertpatient.device = this.eventTs.device()
    let url = this.vendorService.url + 'icon/';
    const pid = alertpatient?.device?.crmModel?.manufacturer?.pid;
    if (translate) {
      result =
        '<li>' +
        '<span class="tooltext"><img class="ico-device me-2" width="14px" src="' + url + pid + '" onError="this.style.display = \'none\';">' + translate + '</span>' +
        '</li>';
    }
    return result
  }

  private deviceTypeHTML(): string {
    let result = ''
    let alertpatient = new AlertPatient()
    if (this.eventTs) alertpatient.device = this.eventTs.device()
    let imgDevice = alertpatient.deviceImgPath
    if (this.eventTs && this.eventTs.deviceModelType()) {
      result = '<li>' +
        '<span class="tooltext"><img class="ico-device me-2" width="14px" src="' + imgDevice + '" >' + this.translate('DEVICE.' + this.eventTs.deviceModelType()) + '</span>' +
        '</li>';
    }
    return result
  }

  private serialNumberHTML(): string {
    let result = ''
    if (this.eventTs && this.eventTs.serialNumber()) {
      return '<li>' +
        '<span class="tooltext"><i class="fas fa-barcode me-2"></i>' + this.eventTs.serialNumber() + '</span>' +
        '</li>';
    }
    return result
  }
  private finalizedHTML(): string {
    let result = '';
    if (this.eventTs && !this.eventTs.isFinalized() && this.eventTs.checkClinicalEvent()) {
      result = '<li>' +
        '<span class="tooltext me-2">' +
        '<i class="fa fa-exclamation-triangle text-warning me-2"></i>'
        + this.translate('Appointment.discontinued') + '</span>' +
        '</li>';
    }
    return result;
  }
  private conflictHTML(): string {
    let result = '';
    if (this.eventTs && this.eventTs.conflict()) {
      result = '<li>' +
        '<span class="tooltext me-2">' +
        '<i class="fa fa-adjust text-danger me-2"></i>'
        + this.translate('CONFLICTS.FusionError') + '</span>' +
        '</li>';
    }
    return result;
  }
  private operationTypeHTML(): string {
    let result = '';
    if (this.eventTs && this.eventTs.operationType()) {
      for (const iterator of this.eventTs.operationType()) {
        result += '<li>' +
          '<span class="tooltext">' +
          '<span class="font-weight-bold me-2">' + this.eventTs.getOperationTypeIconByType(iterator) + '</span>'
          + this.translate('OPERATION.OperationType.' + iterator) + '</span>' +
          '</li>';
      }
    }
    return result;
  }
  private deviceCameralTypeHTML(): string {
    let result = '';
    if (this.eventTs && this.eventTs.cameralType() !== '') {
      result = '<li>' +
        '<span class="tooltext">' +
        '<span class="font-weight-bold me-3">' + this.eventTs.cameralTypeIcon() + '</span>'
        + this.translate('OPERATION.DeviceCameralType.' + this.eventTs.cameralType()) + '</span>' +
        '</li>';
    }
    return result;
  }
  private canceledHTML(): string {
    let result = '';
    if (this.eventTs && this.eventTs.isCanceled()) {
      result = '<li>' +
        '<span class="tooltext">' +
        '<i class="fa fa-ban text-danger mr-2"></i>'
        + this.translate('Order.ORDERSTATUS.CANCELED') + '</span>' +
        '</li>';
    }
    return result;
  }
  private patientStatusInOrderHTML(): string {
    let result = '';
    if (this.eventTs && this.eventTs.getPatientStatusInOrder() !== '') {
      result = '<li>' +
        '<span class="tooltext">' +
        '<i class="' + Order.getOrderStatusIcon(this.eventTs.getPatientStatusInOrder()) + ' mr-2"></i>'
        + this.translate('Order.ORDERSTATUS.' + this.eventTs.getPatientStatusInOrder()) + '</span>' +
        '</li>';
    }
    return result;
  }
  private originHTML(): string {
    let result = '';
    const currentLang = this.translateService.currentLang;
    if (this.eventTs && this.eventTs.getOrigin()?.value) {
      const originByLocale = this.eventTs.getOrigin()?.valueByLocale(currentLang);
      const origin = originByLocale ?? this.eventTs.getOrigin()?.value;
      result = '<li>' +
        '<span class="tooltext me-2">' +
        '<img src = "../../assets/images/icons/origin.svg" width="14" height="14" class="me-2"/>'
        + origin + '</span>' +
        '</li>';
    }
    return result;
  }

  private isUpperCaseChar(word: string, charIndex: number) {
    return word.charAt(charIndex).toUpperCase() === word.charAt(charIndex);
  }

  import(): string {
    let result = '';
    if (this.eventTs && this.eventTs.isImported()) {
      const type = this.eventTs.getImportedType();
      if (type) {
        switch (type) {
          case ImportType.INCLINIC_EBOX:
            result = this.translate('TIMELINE.INCLINIC_EBOX');
            break;
          case ImportType.REMOTE:
            result = this.translate('TIMELINE.REMOTE');
            break;
          default:
            break;
        }
      } else {
        result = this.translate('TIMELINE.Import');
      }
    } else {
      result = '';
    }
    return result;
  }

  importIcon(): string {
    let icon = '<i class="' + (this.eventTs ? this.eventTs.importIcon() : '') + '"></i>';
    const type = this.eventTs && this.eventTs.getImportedType();
    if (type) {
      switch (type) {
        case ImportType.INCLINIC_EBOX:
          icon = '<i class="' + (this.eventTs ? this.eventTs.importIcon() : '') + '"></i>';
          break;
        case ImportType.REMOTE:
          icon = '<i class="' + (this.eventTs ? this.eventTs.importIcon() : '') + '"></i>';
          break;
        default:
          icon = '<i class="' + (this.eventTs ? this.eventTs.importIcon() : '') + '"></i>';
          break;
      }
    }
    return icon;
  }

  order(): string {
    let result = '';
    if (this.eventTs) {
      const order = this.eventTs.getOrderType();
      // if order two first chars are uppercase is ClinicalEvent
      const key = this.isUpperCaseChar(order, 0) && this.isUpperCaseChar(order, 1) ? 'CLINICALEVENT' : 'ORDER'
      result = this.translate(`${key}.${order}`);
    }
    return result;
  }
  orderIcon(): string {
    return this.eventTs?.orderTypeIcon() !== '' ? '<i class="' + this.eventTs?.orderTypeIcon() + '"></i>' : '<img src="' + this.eventTs.getOrderTypeSrc() + '" width="14" height="14" class="me-2" >';
  }

  orderSvg(): string {
    return this.eventTs?.getModuleSrc() ? `<img src="${this.eventTs.getModuleSrc()}" width="14" height="14" class="me-2 module-svg" ></label>` : '';
  }
  room(): string {
    return (this.eventTs && this.eventTs.resource && this.eventTs.resource.description) ?
      this.eventTs.resource.description : this.translate('Appointment.noroom');
  }
  schedule(): string {
    return (this.eventTs && this.eventTs.schedule && this.eventTs.schedule.description) ?
      this.eventTs.schedule.description : this.translate('Appointment.notSchedule');
  }
  finalized(): boolean {
    return this.eventTs ? this.eventTs.isFinalized() : false;
  }
  conflict() {
    return this.eventTs ? this.eventTs.conflict() : false;
  }
  translate(text: string): string {
    return this.translateService.instant(text);
  }
}

import { TooltipAbstractFactory } from '@madrija/angular-base/chart';
import { Injectable, Injector } from '@angular/core';
import { Tooltip } from '../models/tooltip.model';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '@madrija/materials-ui/common';
import { CvisAppointment } from '../models/cvis-appointment.model';
import { CvisOrderHistoryDTO } from '../models/cvisOrderHistoryDTO.model';
import { CvisOrderHistoryDTOHelper } from '../helpers/cvisOrderHistoryDTO.helper';

@Injectable()
export class CvisTooltipAbstractFactory extends TooltipAbstractFactory {
    constructor(private translateService: TranslateService, private vendorService: VendorService) {
        super()
    }
    public getInstance(data: { data: CvisOrderHistoryDTO, injector: Injector }): Tooltip {
        const tooltip = new Tooltip(this.translateService, this.vendorService);
        tooltip.eventTs = new CvisAppointment(CvisOrderHistoryDTOHelper.toAppointment(data.data));
        return tooltip;
    }
}

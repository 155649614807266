import { PatientHistoryOrder } from '@madrija/medical-ui/common';
import { CrmHistoryDTO } from '@madrija/crm-ui/common';
import { Order } from '@madrija/medical-ui/common';

export class CvisOrderHistoryDTO extends PatientHistoryOrder {
  public crmHistoryDTO: CrmHistoryDTO | null = null

  override get module(): string {
    switch (this.dtype) {
      case 'CrmFollowUp': case 'CrmOperation': return 'madrija-crm';
      case 'EpProcedure': return 'madrija-electro';
      case 'SihProcedure': case 'SihFollowUp': return 'madrija-sih';
      case 'CimEcoCardio': case 'CimTACCardio': case 'CimRMCardio': return 'enigma-cardio-image';
      case 'CrFirstVisit': case 'CrPreProgram': case 'CrTrainingSession': case 'CrPharmacy': case 'CrNutritionistVisit': case 'CrPsychologistVisit': case 'CrCaseClosureVisit': case 'CrFollowUp': return 'madrija-cardiorehab';
      case 'EcgTest': return 'madrija-ecg';
      case 'VasProcedure': case 'VasFollowUp': return 'madrija-vascular';
      default: return '';
    }
  }
}

export class OrderDTO extends Order {
  public autoFill: Date | null = null;
  public importType: string | null = null;
  public operationType: any[] | null = null;
  public operationDeviceType: string | null = null;
  public cameralType: string | null = null;
}

import { Injectable } from '@angular/core';
import { PriorityType, BradycardiaElectrodeType, ShockCountType, ImportType, FollowupType, DeviceCameralType, DeviceType, OperationType, TachycardiaType } from '@madrija/crm-ui/common';
import { ClinicalEventType, FlagColors, OrderStatus, Scope, ScopeType, Sex, WorkOutType, TrainingPhase, OrderPlannerType, DegreeOfKinship, DeviceType as MedDeviceType } from '@madrija/medical-ui/common';
import { EnumFactory } from '@madrija/base-ui/dynamic-form';
import { CardiacAppendageType } from '@madrija/cardio-image-ui/common';
import { PTCAType } from '@madrija/sih-ui/common';
import { Veins, Substrates, NYHAClass, Grade, BrugadaType, TurnDirection, AblationSubstrateType, Transeptal, ProtocolType, EpFaType } from '@madrija/electro-ui/common'
import { TaviType, SegmentalContractility, CCSClass, ValvulopathyType, AnginaType, ArrhythmiaType, RiskType } from '@madrija/cardiovascular-ui/common';

@Injectable()
export class CvisEnumFactoryService extends EnumFactory {

  getMap() {
    return new Map()
      //CV
      .set('RiskType', RiskType)
      //CRM
      .set('Sex', Sex).set('ClinicalEventType', ClinicalEventType).set('Scope', Scope).set('ScopeType', ScopeType).set('OrderStatus', OrderStatus).set('FlagColors', FlagColors)
      .set('PriorityType', PriorityType).set('BradycardiaElectrodeType', BradycardiaElectrodeType).set('ImportType', ImportType).set('FollowupType', FollowupType)
      .set('DeviceType', DeviceType).set('DeviceCameralType', DeviceCameralType).set('OperationType', OperationType).set('TachycardiaType', TachycardiaType).set('ShockCountType', ShockCountType)
      //CIM
      .set('SegmentalContractility', SegmentalContractility)
      .set('CardiacAppendageType', CardiacAppendageType)
      .set('TaviType', TaviType)
      //SIH
      .set('PTCAType', PTCAType)
      //EP
      .set('Veins', Veins)
      .set('Substrates', Substrates)
      .set('NYHAClass', NYHAClass)
      .set('Grade', Grade)
      .set('BrugadaType', BrugadaType)
      .set('TurnDirection', TurnDirection)
      .set('ProtocolType', ProtocolType)
      .set('Transeptal', Transeptal)
      .set('AblationSubstrateType', AblationSubstrateType)
      .set('EpFaType', EpFaType)
      //CR
      .set('CCSClass', CCSClass).set('WorkOutType', WorkOutType).set('TrainingPhase', TrainingPhase).set('OrderPlannerType', OrderPlannerType)
      .set('MedDeviceType', MedDeviceType).set('ValvulopathyType', ValvulopathyType).set('ArrhythmiaType', ArrhythmiaType).set('AnginaType', AnginaType)
      .set('DegreeOfKinship', DegreeOfKinship)
  }
}


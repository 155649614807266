import { ReportViewerModalComponent } from "@madrija/base-ui/shared-components";
import { ReportButtonIntegrationAbstractFactory } from "@madrija/base-ui/common";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { MdiResponseMessageHelper, MdiResponseMessageTranslation, OrderHelper, OrderService, ReportSentHelper, Order } from "@madrija/medical-ui/common";
import { Inject, Injectable } from "@angular/core";
import { FollowUpService, OperationService } from "@madrija/crm-ui/common";
import { IntegrationAuxComponent } from "../components/integration-aux/integration-aux.component";
import { SessionService } from "@madrija/angular-base/common";
import { Subject } from "rxjs";
import { GenerateEvent } from "@madrija/reporter";

@Injectable()
export class ReportButtonIntegrationFactory extends ReportButtonIntegrationAbstractFactory {
  static integrations = ['save', 'oru', 'print', 'gdt', 'oru_directory', 'sec', 'sms', 'nationalRegistry', 'mdm_t02', 'mdm_t01', 'clinicalCourse', 'deviceCard', 'dicom', 'oru_appreason'];
  locale: string = 'en';
  constructor(public httpClient: HttpClient, public orderService: OrderService, public followupService: FollowUpService, public operationService: OperationService,
    public sessionService: SessionService, /**@Inject(LOCALE_ID) public locale: string,**/  @Inject('LOCALE_SUBJECT') locale: Subject<any>) {
    super()
    locale.subscribe((my_value) => {
      this.locale = my_value
    });
  }

  getAvailableIntegrations(): Array<string> {
    return ReportButtonIntegrationFactory.integrations;
  }

  override getIntegration(category: string): Function {
    switch (category) {
      case 'save': return save;
      case 'oru': return oru;
      case 'print': return print;
      case 'gdt': return gdt;
      case 'oru_directory': return oru_directory;
      case 'sec': return sec;
      case 'sms': return sms;
      case 'nationalRegistry': return nationalRegistryReport;
      case 'mdm_t02': return mdmt02;
      case 'mdm_t01': return mdmt01;
      case 'clinicalCourse': return clinicalCourse;
      case 'deviceCard': return deviceCard;
      case 'dicom': return dicom;
      case 'oru_appreason': return oru_appreason;
      default:
        return function (this: ReportViewerModalComponent) { console.log('falta implementar'); this.loadingNotDestoyReport = false; };
    }
  }

}

function updateReportSentList(this: ReportViewerModalComponent, factory: ReportButtonIntegrationFactory) {
  if (this.masterReportId) {
    factory.orderService.get(this.masterReportId, '?result=medReportSentList').subscribe(
      response => {
        if (response.status === 0 && response.data) {
          const order = OrderHelper.buildFromJSON(response.data);
          this.reportSentMap = ReportSentHelper.buildReportSentMapFromReportSentList(order.medReportSentList ?? []);
        }
      }
    )
  }
}

function createComponent(this: ReportViewerModalComponent): IntegrationAuxComponent | undefined {
  this.content?.clear();
  const componentRef = this.content?.createComponent(IntegrationAuxComponent);
  componentRef?.changeDetectorRef.detectChanges()
  return componentRef?.instance;
}

function fnFactory(fn: string): Function {
  switch (fn) {
    case 'sendSMS': return sendSMS;
    case 'hideLoadingNotDestoyReport': return hideLoadingNotDestoyReport;
    case 'savePlaceOrder': return savePlaceOrder;
    case 'printDeviceCard': return printDeviceCard;
    case 'send_oru_appreason': return send_oru_appreason;
    default: return () => { };
  }
}

function save(this: ReportViewerModalComponent, title: string, base64: string, _reportId: number, _factory: ReportButtonIntegrationFactory) {
  const blob = this.convertBase64ToBlobData(base64);
  this.downloadBlob(blob, title);
  this.loadingNotDestoyReport = false;
}

function sendReport(this: ReportViewerModalComponent, reportId: number, factory: ReportButtonIntegrationFactory, integrationType: string, integrationEnum: string, toDirectory = false, selection?: Array<string>) {
  factory.orderService.sendReport(this.masterReportId!, reportId, integrationType, toDirectory, selection).subscribe(
    response => {
      if (response.status >= 0) {
        updateReportSentList.call(this, factory);
        this.toastr.success(this.translateService.instant('REPORT.SentReport'), this.translateService.instant('REPORT.Report'));
        this.loadingNotDestoyReport = false;
      } else {
        this.handleError(response, this.translateService.instant('REPORT.Report'));
        this.loadingNotDestoyReport = false;
      }
      this.onSendIntegration.emit(integrationEnum);
    },
    error => {
      this.loadingNotDestoyReport = false;
      const status = error.error.status
      let title = this.translateService.instant('REPORT.Report')
      switch (status) {
        case -50010:
          this.toastr.error(this.translateService.instant('TIMELINE.ClinicalCourseNotNumberHistory'), title);
          break;
        case -50011:
          this.toastr.error(this.translateService.instant('TIMELINE.ClinicalCourseNotIdEpisode'), title);
          break;
        case -50014:
          this.toastr.error(this.translateService.instant('TIMELINE.notService'), title);
          break;
        default:
          this.handleError(error, title);
          break;
      }
      this.onSendIntegration.emit(integrationEnum);
    });
}

function oru(this: ReportViewerModalComponent, title: string, _base64: string, reportId: number, factory: ReportButtonIntegrationFactory) {
  sendReport.call(this, reportId, factory, 'ORU_R01', 'oru');
}

function print(this: ReportViewerModalComponent, _title: string, base64: string, _reportId: number, _factory: ReportButtonIntegrationFactory) {
  const blob = this.convertBase64ToBlobData(base64);
  const blobUrl = URL.createObjectURL(blob);
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = blobUrl;
  document.body.appendChild(iframe);
  if (iframe.contentWindow) {
    iframe.contentWindow.print();
  }
  this.loadingNotDestoyReport = false;
}

function gdt(this: ReportViewerModalComponent, _title: string, _base64: string, _reportId: number, factory: ReportButtonIntegrationFactory) {
  factory.orderService.exportGDTReport(this.masterReportId!).subscribe(
    response => {
      if (response.status >= 0) {
        updateReportSentList.call(this, factory);
        this.toastr.success(this.translateService.instant('REPORT.ExportReport'), this.translateService.instant('REPORT.Report'));
      } else {
        this.handleError(response, this.translateService.instant('REPORT.Report'));
      }
      this.onSendIntegration.emit('gdt');
    },
    error => {
      this.handleError(error, this.translateService.instant('REPORT.NotExportReport'));
      this.onSendIntegration.emit('gdt');
    });
  this.loadingNotDestoyReport = false;
}

function oru_directory(this: ReportViewerModalComponent, _title: string, _base64: string, reportId: number, factory: ReportButtonIntegrationFactory) {
  sendReport.call(this, reportId, factory, 'ORU_R01', 'oru_directory', true);
}

function sec(this: ReportViewerModalComponent, title: string, base64: string, factory: ReportButtonIntegrationFactory) {
  if (this.masterReportId) {
    factory.operationService.sendSec(this.masterReportId).subscribe(
      response => {
        if (response.status === 0) {
          updateReportSentList.call(this, factory);
          this.toastr.success(this.translateService.instant('REPORT.SentReport'), this.translateService.instant('REPORT.Report'));
        } else {
          this.toastr.error(this.translateService.instant('REPORT.PdfSMSError'), this.translateService.instant('REPORT.Report'));
        }
        this.onSendIntegration.emit('sec');
        this.loadingNotDestoyReport = false;
      },
      error => {
        const status = error.error.status
        let title = this.translateService.instant('REPORT.Report');
        let message: string;
        switch (status) {
          case -1:
            this.toastr.error(this.translateService.instant('REPORT.NotInDB'), title);
            break;
          case -2600:
            message = (error.error.data && error.error.data == 'Unrecognized Device Type') ?
              this.translateService.instant('REPORT.UnrecognizedDeviceType') : this.translateService.instant('REPORT.SaveCascadeNull');
            this.toastr.error(message, title);
            break;
          case -2601:
            message = (error.error.data) ? error.error.data : '';
            this.toastr.error(message, title);
            break;
          case -2602:
            message = (error.error.data) ? error.error.data : '';
            //TODO: validación interna de los campos formados no es correcta
            this.toastr.error(message, title);
            break;
          default:
            this.handleError(error, title);
            break;
        }
        this.onSendIntegration.emit('sec');
        this.loadingNotDestoyReport = false;
      });
  } else {
    this.toastr.error(this.translateService.instant('REPORT.PdfSMSError'), this.translateService.instant('REPORT.Report'));
  }
}

function sms(this: ReportViewerModalComponent, title: string, base64: string, factory: ReportButtonIntegrationFactory) {
  const component = createComponent.call(this)
  if (!(this.publicMasterReport as Order)?.appointment?.patient?.favouritePhone) {
    component?.sureSendSMS?.show();
    component?.callFunction.subscribe((fn: { fn: string }) => { fnFactory(fn.fn).call(this, factory) });
  } else {
    sendSMS.call(this, factory);
  }
}

function sendSMS(this: ReportViewerModalComponent, factory: ReportButtonIntegrationFactory) {
  const masterReport = this.publicMasterReport as Order;
  if (masterReport && masterReport.appointment && masterReport.appointment.patient && masterReport.appointment.patient.pid) {
    let patientID = masterReport.appointment.patient.pid;
    if (this.masterReportId) {
      factory.orderService.sendSMS(patientID, this.masterReportId).subscribe(
        response => {
          switch (response.status) {
            case 0:
              updateReportSentList.call(this, factory);
              this.toastr.success(this.translateService.instant('REPORT.SendSMS'), this.translateService.instant('REPORT.Report'));
              break;
            case -2524:
              this.toastr.success(this.translateService.instant('REPORT.noValidFavouritePhone'), this.translateService.instant('REPORT.Report'));
              break;
            default:
              this.toastr.error(this.translateService.instant('REPORT.PdfSMSError'), this.translateService.instant('REPORT.Report'));
              break;
          }
          this.onSendIntegration.emit('sms');
          this.loadingNotDestoyReport = false;
        },
        error => {
          const status = error.error.status
          let title = this.translateService.instant('REPORT.Report')
          switch (status) {
            case -50009:
              this.toastr.error(this.translateService.instant('REPORT.SMSErrorPetition'), title);
              break;
            case -50008:
              this.toastr.error(this.translateService.instant('REPORT.SMSErrorNHC'), title);
              break;
            case -2550:
              this.toastr.error(this.translateService.instant('REPORT.SMSErrorId'), title);
              break;
            case -2534:
              this.toastr.error(this.translateService.instant('REPORT.SMSErrorPetition'), title);
              break;
            case -2533:
              this.toastr.error(this.translateService.instant('REPORT.SMSErrorNHC'), title);
              break;
            case -2528:
              this.toastr.error(this.translateService.instant('REPORT.SMSErrorId'), title);
              break;
            case -2535://SMS property is needed in INTEGRATION_CONFIG
              this.toastr.error(this.translateService.instant('REPORT.SMSNeededIntegrationConfig'), title);
              break;
            case -2536://SMS_BAD_PROVIDER_CONFIG = -2536 (SMS provider not configured.)
              this.toastr.error(this.translateService.instant('REPORT.SMSProviderConfig'), title);
              break;
            case -2537://SMS_BAD_TYPE_CONFIG = -2537 (Property SMS doesn't contain the sms type.)
              this.toastr.error(this.translateService.instant('REPORT.SMSNeededTypeProperty'), title);
              break;
            default:
              this.handleError(error, title);
              break;
          }
          this.onSendIntegration.emit('sms');
          this.loadingNotDestoyReport = false;
        }
      )
    }
  } else {
    this.toastr.error(this.translateService.instant('REPORT.PdfSMSError'), this.translateService.instant('REPORT.Report'));
    this.loadingNotDestoyReport = false;
  }
}

function nationalRegistryReport(this: ReportViewerModalComponent, title: string, base64: string, factory: ReportButtonIntegrationFactory) {
  const masterReport = this.publicMasterReport as Order;
  const patientPid = (masterReport && masterReport.appointment && masterReport.appointment.patient && masterReport.appointment.patient.pid) ? masterReport.appointment.patient.pid : 0;
  if (this.masterReportId) {
    factory.operationService.sendNationalRegistry(patientPid, this.masterReportId).subscribe(
      response => {
        if (response.status >= 0) {
          updateReportSentList.call(this, factory);
          this.toastr.success(this.translateService.instant('REPORT.SentReport'), this.translateService.instant('REPORT.Report'));
        } else {
          this.handleError(response, this.translateService.instant('REPORT.Report'));
        }
        this.onSendIntegration.emit('nationalRegistry');
        this.loadingNotDestoyReport = false;
      },
      error => {
        // if (error.error.status === -2501) {
        //   this.toastr.error(error.error.data, this.translateService.instant('REPORT.NotSentReport'));
        // } else
        if (error.error.status === -2500 || error.error.status === -2501) {
          const chpaceMsg = getMdiMessageTranslation.call(factory, error.error.data);
          const component = createComponent.call(this);
          component?.showModalTemplate(chpaceMsg, this.translateService.instant('REPORT.NotSentReport'));
        } else if (error.error.status === -1) {
          this.toastr.error(getMdiMessageTranslation.call(factory, error.error.data), this.translateService.instant('REPORT.NotSentReport'));
        } else {
          this.handleError(error, this.translateService.instant('REPORT.NotSentReport'));
        }
        this.onSendIntegration.emit('nationalRegistry');
        this.loadingNotDestoyReport = false;
      });
  }
}

function hideLoadingNotDestoyReport(this: ReportViewerModalComponent) {
  this.onSendIntegration.emit();
  this.loadingNotDestoyReport = false;
}

function getMdiMessageTranslation(this: ReportButtonIntegrationFactory, data: any[]): string {
  let messageTranslation: MdiResponseMessageTranslation[] = [];
  let message = '';
  if (data) {
    for (const iterator of data) {
      messageTranslation.push(MdiResponseMessageHelper.buildFromJSON(iterator));
    }
  }
  const messageTranslated = messageTranslation.find((m) => { if (m.language && m.language.locale && m.language.locale == this.locale) return m; return; });
  if (messageTranslated && messageTranslated.translatedMessage) {
    message = messageTranslated.translatedMessage;
  } else if (messageTranslation[0] && messageTranslation[0].mdiResponseMessage && messageTranslation[0].mdiResponseMessage.responseMessage) {
    message = messageTranslation[0].mdiResponseMessage.responseMessage;
  }
  return message;
}

function mdmt02(this: ReportViewerModalComponent, _title: string, _base64: string, reportId: number, factory: ReportButtonIntegrationFactory) {
  sendReport.call(this, reportId, factory, 'MDM_T02', 'mdm_t02');
}
function mdmt01(this: ReportViewerModalComponent, _title: string, _base64: string, reportId: number, factory: ReportButtonIntegrationFactory) {
  sendReport.call(this, reportId, factory, 'MDM_T01', 'mdm_t01');
}

function clinicalCourse(this: ReportViewerModalComponent, title: string, base64: string, factory: ReportButtonIntegrationFactory) {
  if (this.masterReportId) {
    factory.orderService.exportClinicalCourse(this.masterReportId).subscribe(
      response => {
        if (response.status === 0) {
          updateReportSentList.call(this, factory);
          this.toastr.success(this.translateService.instant('TIMELINE.ClnicalCourseExportSucessfully'), this.translateService.instant('TIMELINE.ExportClinicalCourse'));
        } else {
          this.handleError(response, this.translateService.instant('TIMELINE.ExportClinicalCourse'));
        }
        this.onSendIntegration.emit('clinicalCourse');
        this.loadingNotDestoyReport = false;
      },
      error => {
        onResponseError.call(this, factory, error);
        this.onSendIntegration.emit('clinicalCourse');
        this.loadingNotDestoyReport = false;
      }
    );
  }
}

function onResponseError(this: ReportViewerModalComponent, factory: ReportButtonIntegrationFactory, error: HttpErrorResponse) {
  const status = error.error.status;
  let msg = '';
  const title = this.translateService.instant('TIMELINE.ExportClinicalCourse');
  switch (status) {
    case -1:
      msg = error.error.data;
      this.toastr.error(msg, title);
      break;
    case -2512:
      msg = this.translateService.instant('Appointment.WORKLIST_REST_FAIL');
      this.toastr.error(msg, title);
      break;
    case -2513:
      msg = this.translateService.instant('Appointment.WORKLIST_MDI_FAIL');
      this.toastr.error(msg, title);
      break;
    case -50010:
      msg = this.translateService.instant('TIMELINE.ClinicalCourseNotNumberHistory');
      this.toastr.error(msg, title);
      break;
    case -2527:
      msg = this.translateService.instant('TIMELINE.ClinicalCourseNotIdEpisode');
      this.toastr.error(msg, title);
      const component = createComponent.call(this);
      component?.notEpisodeIDModal(error.error.data);
      component?.callFunction.subscribe((fn: { fn: string, params: any[] }) => { fnFactory(fn.fn).call(this, factory, component, ...fn.params) });
      break;
    case -50012:
      msg = this.translateService.instant('TIMELINE.ClinicalCourseNotTreatment');
      this.toastr.error(msg, title);
      break;
    case -50013:
      msg = this.translateService.instant('TIMELINE.EmptyFollowupComment');
      this.toastr.error(msg, title);
      break;
    case -2528:
      msg = this.translateService.instant('TIMELINE.ClinicalCourseNotValidId');
      this.toastr.error(msg, title);
      break;
    default:
      this.handleError(error, this.translateService.instant('TIMELINE.ExportClinicalCourse'));
      break;
  }
}

function savePlaceOrder(this: ReportViewerModalComponent, factory: ReportButtonIntegrationFactory, component: IntegrationAuxComponent, placerOrder: string) {
  if (this.masterReportId) {
    this.loadingNotDestoyReport = true;
    factory.orderService.savePlaceOrder(placerOrder, this.masterReportId).subscribe(
      response => {
        if (response.status === 0) {
          updateReportSentList.call(this, factory);
          this.toastr.success(this.translateService.instant('TIMELINE.ClnicalCourseExportSucessfully'), this.translateService.instant('TIMELINE.ExportClinicalCourse'));
        } else {
          this.handleError(response, this.translateService.instant('TIMELINE.ExportClinicalCourse'));
        }
        if (component.modalRef) component.modalRef.close();
        this.onSendIntegration.emit('clinicalCourse');
        this.loadingNotDestoyReport = false;
      },
      error => {
        this.loadingNotDestoyReport = false;
        onResponseError.call(this, factory, error);
        this.onSendIntegration.emit('clinicalCourse');
        if (component.modalRef) component.modalRef.close();
      }
    );
  }
}

function deviceCard(this: ReportViewerModalComponent, title: string, base64: string, factory: ReportButtonIntegrationFactory) {
  const component = createComponent.call(this);
  component?.showConfirmationPrintModal();
  component?.callFunction.subscribe((fn: { fn: string }) => { fnFactory(fn.fn).call(this, factory, component) });
}

function printDeviceCard(this: ReportViewerModalComponent, factory: ReportButtonIntegrationFactory, component: IntegrationAuxComponent) {
  const masterReport = this.publicMasterReport as Order;
  if (masterReport.appointment?.patient?.pid) {
    let patientID = masterReport.appointment.patient.pid;
    if (this.masterReportId) {
      this.loadingNotDestoyReport = true;
      factory.followupService.printPatientCard(patientID, this.masterReportId).subscribe(
        response => {
          if (response.status == 0) {
            updateReportSentList.call(this, factory);
            this.toastr.success(this.translateService.instant('TIMELINE.CardSendedToPrinter'), this.translateService.instant('TIMELINE.PrintCard'));
          } else {
            this.handleError(response, this.translateService.instant('TIMELINE.PrintCard'));
          }
          if (component.cardModalRef) component.cardModalRef.close();
          this.onSendIntegration.emit('deviceCard');
          this.loadingNotDestoyReport = false;
        }, error => {
          switch (error.error.status) {
            case -50015:
              this.toastr.error(this.translateService.instant('TIMELINE.NoPatientIdData'), this.translateService.instant('TIMELINE.PrintCard'));
              break;
            case -50016:
              this.toastr.error(this.translateService.instant('TIMELINE.NoDeviceData'), this.translateService.instant('TIMELINE.PrintCard'));
              break;
            case -50017:
              this.toastr.error(this.translateService.instant('TIMELINE.NoElectrodeData'), this.translateService.instant('TIMELINE.PrintCard'));
              break;

            default:
              this.handleError(error, this.translateService.instant('TIMELINE.PrintCard'));
              break;
          }
          if (component.cardModalRef) component.cardModalRef.close();
          this.onSendIntegration.emit('deviceCard');
          this.loadingNotDestoyReport = false;
        }
      );
    }
  }
}
function dicom(this: ReportViewerModalComponent, title: string, _base64: string, reportId: number, factory: ReportButtonIntegrationFactory) {
  sendReport.call(this, reportId, factory, 'DICOM', 'dicom');
}

function oru_appreason(this: ReportViewerModalComponent, title: string, base64: string, factory: ReportButtonIntegrationFactory) {
  if (this.masterReportId) {
    factory.orderService.icsAppReason(this.masterReportId).subscribe(
      response => {
        if (response.status === 0 && response.data) {
          const component = createComponent.call(this);
          component?.showIcsAppReasonModal(response.data);
          component?.callFunction.subscribe((fn: { fn: string, params: any[] }) => { fnFactory(fn.fn).call(this, factory, component, ...fn.params) });
        }
      }
    );

  }
}

function send_oru_appreason(this: ReportViewerModalComponent, factory: ReportButtonIntegrationFactory, component: IntegrationAuxComponent, selection: Array<string>) {
  this.loadingNotDestoyReport = false;
  setTimeout(() => {
    const subscription = this.reportComponent?.generatePdf.subscribe((event: GenerateEvent) => {
      this.saveReport(event).then((value: { saved: boolean, extraParams?: any | undefined }) => {
        this.loadingNotDestoyReport = false;
        if (value.saved) sendReport.call(this, value.extraParams?.reportID, factory, 'ORU_R01', 'oru_directory', true, selection);
      });
      subscription?.unsubscribe();
    });
    this.loadingNotDestoyReport = true;
    this.reportComponent?.toPdf(false)
  }, 0);
}

import { BasicHelper } from "@madrija/angular-base/common";
import { FilePointerHelper, KeyValueHelper, OrderHelper } from "@madrija/base-ui/common";
import {
  ArrhythmicDebutFADate, AppointmentHistoryDTO, IoTMeasurementDTO, Appointment, Patient,
  Resource, Schedule, Service, AppointmentReasons,
  ClinicalEventType
} from "@madrija/medical-ui/common";
import { CvisOrderHistoryDTO, OrderDTO } from "../models/cvisOrderHistoryDTO.model";
import { CrmHistoryDTO } from '@madrija/crm-ui/common';
import { OrderDTOHelper } from "./orderDTO.helper";

export class CvisOrderHistoryDTOHelper {

  static buildFromJSON(json: any): CvisOrderHistoryDTO {
    const cvisOrderHistoryDTO = BasicHelper.buildFromJson(new CvisOrderHistoryDTO, json);
    if (json.additionalAttribute && json.additionalAttribute != null && json.dtype === ClinicalEventType.FA_DEBUT) {
      cvisOrderHistoryDTO.additionalAttribute = BasicHelper.buildFromJson(new ArrhythmicDebutFADate, json.additionalAttribute);
    }
    if (json.type && json.type != null) {
      cvisOrderHistoryDTO.type = KeyValueHelper.buildFromJSON(json.type);
    }
    if (json.appointmentHistoryDTO && json.appointmentHistoryDTO != null) {
      cvisOrderHistoryDTO.appointmentHistoryDTO = BasicHelper.buildFromJson(new AppointmentHistoryDTO, json.appointmentHistoryDTO);
      if (cvisOrderHistoryDTO.appointmentHistoryDTO.patientRecordModulesCloseDates) cvisOrderHistoryDTO.appointmentHistoryDTO.patientRecordModulesCloseDates = new Map(Object.keys(json.appointmentHistoryDTO.patientRecordModulesCloseDates).map((value: string) => [value, BasicHelper.isDateFromString(json.appointmentHistoryDTO.patientRecordModulesCloseDates[value]) ? new Date(json.appointmentHistoryDTO.patientRecordModulesCloseDates[value]) : json.appointmentHistoryDTO.patientRecordModulesCloseDates[value]]));
    }
    if (json.filePointers && json.filePointers != null) {
      const list = [];
      for (const file of json.filePointers) {
        list.push(FilePointerHelper.buildFromJSON(file));
      }
      cvisOrderHistoryDTO.filePointers = list;
    }
    if (json.iotMeasurements && json.iotMeasurements != null) {
      const list = [];
      for (const meas of json.iotMeasurements) {
        const dto = BasicHelper.buildFromJson(new IoTMeasurementDTO, meas)
        if (dto.valueByDateTime) dto.valueByDateTime = new Map(Object.keys(dto.valueByDateTime).map((value: string) => [BasicHelper.isDateFromString(value) ? new Date(value.replace(' ', 'T')) : value, Number(dto.valueByDateTime[value])]));
        list.push(dto);
      }
      cvisOrderHistoryDTO.iotMeasurements = list;
    }
    if (json.crmHistoryDTO && json.crmHistoryDTO != null) {
      cvisOrderHistoryDTO.crmHistoryDTO = BasicHelper.buildFromJson(new CrmHistoryDTO, json.crmHistoryDTO);
    }
    return cvisOrderHistoryDTO;
  }

  static toAppointment(cvisOrderHistoryDTO: CvisOrderHistoryDTO): Appointment {
    const appointment = new Appointment;
    appointment.start = cvisOrderHistoryDTO.appointmentHistoryDTO!.start;
    if (cvisOrderHistoryDTO.appointmentHistoryDTO!.patientName || cvisOrderHistoryDTO.appointmentHistoryDTO!.patientSurname) {
      appointment.patient = new Patient;
      appointment.patient.name = cvisOrderHistoryDTO.appointmentHistoryDTO!.patientName;
      appointment.patient.surname = cvisOrderHistoryDTO.appointmentHistoryDTO!.patientSurname;
    }
    if (cvisOrderHistoryDTO.appointmentHistoryDTO!.resourceColorClass || cvisOrderHistoryDTO.appointmentHistoryDTO!.resourceDescription) {
      appointment.resource = new Resource;
      appointment.resource.colorClass = cvisOrderHistoryDTO.appointmentHistoryDTO!.resourceColorClass;
      appointment.resource.description = cvisOrderHistoryDTO.appointmentHistoryDTO!.resourceDescription;
    }
    if (cvisOrderHistoryDTO.appointmentHistoryDTO!.scheduleDescription || cvisOrderHistoryDTO.appointmentHistoryDTO!.scheduleColor) {
      appointment.schedule = new Schedule;
      appointment.schedule.description = cvisOrderHistoryDTO.appointmentHistoryDTO!.scheduleDescription;
      appointment.schedule.color = cvisOrderHistoryDTO.appointmentHistoryDTO!.scheduleColor;
    }
    if (cvisOrderHistoryDTO.appointmentHistoryDTO!.appointmentReasonsCode || cvisOrderHistoryDTO.appointmentHistoryDTO!.appointmentReasonsText) {
      appointment.appointmentReasons = new AppointmentReasons;
      appointment.appointmentReasons.code = cvisOrderHistoryDTO.appointmentHistoryDTO!.appointmentReasonsCode;
      appointment.appointmentReasons.text = cvisOrderHistoryDTO.appointmentHistoryDTO!.appointmentReasonsText;
    }
    if (cvisOrderHistoryDTO.appointmentHistoryDTO!.serviceDescription) {
      appointment.service = new Service;
      appointment.service.description = cvisOrderHistoryDTO.appointmentHistoryDTO!.serviceDescription;
    }
    const order: any[] = [OrderDTOHelper.buildFromCvisOrderDTO(
      {
        pid: cvisOrderHistoryDTO.orderId, finalized: cvisOrderHistoryDTO.finalized, orderStatus: cvisOrderHistoryDTO.orderStatus,
        conflict: cvisOrderHistoryDTO.conflict, dtype: cvisOrderHistoryDTO.dtype, autoFill: cvisOrderHistoryDTO.autofill, importType: cvisOrderHistoryDTO.importType,
        operationType: cvisOrderHistoryDTO.crmHistoryDTO?.operationType, operationDeviceType: cvisOrderHistoryDTO.crmHistoryDTO?.operationDeviceType, cameralType: cvisOrderHistoryDTO.crmHistoryDTO?.cameralType,
        modelNamePid: cvisOrderHistoryDTO.crmHistoryDTO?.modelNamePid, modelNameValue: cvisOrderHistoryDTO.crmHistoryDTO?.modelNameValue, modelNameTranslations: cvisOrderHistoryDTO.crmHistoryDTO?.modelNameTranslations,
        modelType: cvisOrderHistoryDTO.crmHistoryDTO?.modelType, serialNumber: cvisOrderHistoryDTO.crmHistoryDTO?.serialNumber, vendorName: cvisOrderHistoryDTO.crmHistoryDTO?.vendorName, vendorPid: cvisOrderHistoryDTO.crmHistoryDTO?.vendorPid,
        originPid: cvisOrderHistoryDTO.originPid, originValue: cvisOrderHistoryDTO.originValue, originTranslations: cvisOrderHistoryDTO.originTranslations, priorityPid: cvisOrderHistoryDTO.priorityPid, priorityValue: cvisOrderHistoryDTO.priorityValue, priorityTranslations: cvisOrderHistoryDTO.priorityTranslations
      })];
    appointment.order = order;
    return appointment;
  }
}

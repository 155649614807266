import { Injectable } from "@angular/core";
import { OrderHelper, OrderAbstractFactory } from "@madrija/medical-ui/common";
import { ProcedureHelper as EpProcedureHelper } from "@madrija/electro-ui/common";
import { ProcedureHelper as SihProcedureHelper } from "@madrija/sih-ui/common";
import { FollowUpHelper, OperationHelper } from "@madrija/crm-ui/common";
import { EcoCardioHelper, TacCardioHelper, RmCardioHelper } from '@madrija/cardio-image-ui/common'
import { ProcedureHelper as VasProcedureHelper, FollowupHelper as VasFollowUpHelper } from '@madrija/vascular-ui/common'
import { CrCaseClosureVisitHelper, CrFirstVisitHelper, CrFollowUpHelper, CrPreProgramHelper, CrTrainingSessionHelper, CrPsychologistVisitHelper, CrNutritionistVisitHelper, CrPharmacyHelper } from "@madrija/cardiorehab-ui/common";

@Injectable()
export class CvisOrderAbstractFactory extends OrderAbstractFactory {
  public getInstance(dtype: string): any {
    let value = null;
    switch (dtype) {
      case 'CrmOperation':
        value = OperationHelper;
        break;
      case 'CrmFollowUp':
        value = FollowUpHelper;
        break;
      case 'EpProcedure':
        value = EpProcedureHelper;
        break;
      case 'SihProcedure':
        value = SihProcedureHelper;
        break;
      case 'CimEcoCardio':
        value = EcoCardioHelper
        break;
      case 'CimTACCardio':
        value = TacCardioHelper
        break;
      case 'CimRMCardio':
        value = RmCardioHelper
        break;
      case 'VasProcedure':
        value = VasProcedureHelper
        break;
      case 'VasFollowUp':
        value = VasFollowUpHelper
        break;
      case 'CrFirstVisit':
        value = CrFirstVisitHelper
        break;
      case 'CrTrainingSession':
        value = CrTrainingSessionHelper
        break;
      case 'CrPsychologistVisit':
        value = CrPsychologistVisitHelper;
        break;
      case 'CrFollowUp':
        value = CrFollowUpHelper
        break;
      case 'CrCaseClosureVisit':
        value = CrCaseClosureVisitHelper
        break;
      case 'CrPreProgram':
        value = CrPreProgramHelper
        break;
      case 'CrNutritionistVisit':
        value = CrNutritionistVisitHelper
        break;
      case 'CrPharmacy':
        value = CrPharmacyHelper
        break;
      default:
        value = OrderHelper;
        break;
    }
    return value;
  }
}


import { Injectable, Type } from '@angular/core';
import { ComponentFactoryService } from '@madrija/angular-base/common';
import { AppointmentFormOrderTemplateComponent } from '../components/appointment-order-template/appointment-order-template.component';

@Injectable()
export class CvisComponentFactoryService extends ComponentFactoryService {
    getComponentsMap(): Map<string, Type<any>> {
        const map = new Map();
        map.set('AppointmentFormOrderTemplate', AppointmentFormOrderTemplateComponent)
        return map;
    }
}
import { BasicHelper } from '@madrija/angular-base/common';
import { OrderDTO } from '../models/cvisOrderHistoryDTO.model';
import { Device, DeviceStatus, Model } from '@madrija/crm-ui/common';
import { KeyValue } from '@madrija/angular-base/common';
import { Vendor } from '@madrija/materials-ui/common';

export class OrderDTOHelper {

  static buildFromJSON(json: any): OrderDTO {
    const orderDTO = BasicHelper.buildFromJson(new OrderDTO, json);
    return orderDTO;
  }

  static buildFromCvisOrderDTO(json: any): OrderDTO {
    const orderDTO = BasicHelper.buildFromJson(new OrderDTO, json);
    orderDTO.origin = new KeyValue;
    orderDTO.crmDeviceStatus = new DeviceStatus;
    orderDTO.crmDeviceStatus.crmDevice = new Device;
    orderDTO.crmDeviceStatus.crmDevice.crmModel = new Model;
    orderDTO.crmDeviceStatus.crmDevice.crmModel.name = new KeyValue;
    orderDTO.crmDeviceStatus.crmDevice.crmModel.manufacturer = new Vendor;
    if (json.originPid && json.originPid != null) {
      orderDTO.origin.pid = json.originPid;
    }
    if (json.originValue && json.originValue != null) {
      orderDTO.origin.value = json.originValue;
    }
    if (json.originTranslations && json.originTranslations != null) {
      const translation = Object.entries(json.originTranslations).map(([locale, value]) => {
        return { value, language: { locale } };
      });
      orderDTO.origin.translationValues = translation;
    }
    if (json.priorityPid && json.priorityPid != null) {
      orderDTO.priority.pid = json.priorityPid;
    }
    if (json.priorityValue && json.priorityValue != null) {
      orderDTO.priority.value = json.priorityValue;
    }
    if (json.priorityTranslations && json.priorityTranslations != null) {
      const translation = Object.entries(json.priorityTranslations).map(([locale, value]) => {
        return { value, language: { locale } };
      });
      orderDTO.priority.translationValues = translation;
    }
    if (json.modelNamePid && json.modelNamePid != null) {
      orderDTO.crmDeviceStatus.crmDevice.crmModel.name.pid = json.modelNamePid;
    }
    if (json.modelNameValue && json.modelNameValue != null) {
      orderDTO.crmDeviceStatus.crmDevice.crmModel.name.value = json.modelNameValue;
    }
    if (json.modelNameTranslations && json.modelNameTranslations != null) {
      const translation = Object.entries(json.modelNameTranslations).map(([locale, value]) => {
        return { value, language: { locale } };
      });
      orderDTO.crmDeviceStatus.crmDevice.crmModel.name.translationValues = translation;
    }

    if (json.modelType && json.modelType != null) {
      orderDTO.crmDeviceStatus.crmDevice.crmModel.type = json.modelType;
    }
    if (json.serialNumber && json.serialNumber != null) {
      orderDTO.crmDeviceStatus.crmDevice.serialNumber = json.serialNumber;
    }
    if (json.vendorName && json.vendorName != null) {
      orderDTO.crmDeviceStatus.crmDevice.crmModel.manufacturer.name = json.vendorName;
    }
    if (json.vendorPid && json.vendorPid != null) {
      orderDTO.crmDeviceStatus.crmDevice.crmModel.manufacturer.pid = json.vendorPid;
    }
    return orderDTO;
  }
}

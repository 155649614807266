import { Injectable, Injector } from '@angular/core';
import { UserService, PropertyService, User, Action, PropertyHelper, UserHelper, LicenseService, LanguageService, AuthenticationService, InactivityService, Language, LanguageHelper } from '@madrija/base-ui/common';
import { DataService, DataKeysEnum, ThemeService } from '@madrija/angular-base/common';
import { HttpClient } from '@angular/common/http';
import { DeviceType } from '@madrija/crm-ui/common';
import { AppConfigInterface } from '@madrija/angular-base/installation-config';

@Injectable({ providedIn: 'root' })
export class AppConfig implements AppConfigInterface {
    private userService: UserService | null = null;
    private propertyService: PropertyService | null = null;
    private licenseService: LicenseService | null = null;
    private languageService: LanguageService | null = null;
    private themeService: ThemeService = new ThemeService();
    loaded = true;

    private _user: User | null = null;
    private _langs: any[] = [];
    private _version: string = '0.0.0';
    private _deviceTypes: DeviceType[] = [];
    private _languages: Array<Language> = [];
    constructor(private injector: Injector, private _httpClient: HttpClient, private data: DataService, private inactivityService: InactivityService) { }
    get user(): User | null {
        return this._user;
    }
    get languages(): any[] {
        return this._langs;
    }
    get version(): string {
        return this._version;
    }
    get deviceTypes(): DeviceType[] {
        return this._deviceTypes;
    }
    get base_languages(): Language[] {
        return this._languages;
    }
    public load(forze?: boolean): Promise<boolean> {
        return new Promise((resolve) => {
            const isPublic = window.location.href.includes('/public/') || window.location.href.includes('/login') || window.location.href.includes('/errors/')
            if (!isPublic || forze) {
                this.loaded = true;
                this.instantiateProviders();
                this.getConfig(resolve);
                // get config every 1 hour
                setInterval(() => { this.getConfig(resolve); }, 3600000);
            } else {
                this.loaded = false;
                resolve(false);
            }
        });
    }
    private instantiateProviders() {
        this.userService = new UserService(this._httpClient, this.injector);
        this.propertyService = new PropertyService(this._httpClient, this.injector);
        this.licenseService = new LicenseService(this._httpClient, this.injector);
        this.languageService = new LanguageService(this._httpClient, this.injector);
    }
    private getConfig(resolve: (value: boolean) => void) {
        this.getBaseLanguagues(resolve);
        this.getVersion();
        this.getDeviceTypes();
        this.getValidationsConfig();
        this.getUserConfig(resolve).then(() => { this.fillProperties(resolve); });
    }

    private getDeviceTypes() {
        this.licenseService?.data().subscribe(
            response => {
                if (response.status >= 0) {
                    const types = response.data;
                    for (const iterator of types) {
                        if (DeviceType.hasOwnProperty(iterator)) {
                            this._deviceTypes.push(iterator);
                        }
                    }
                }
            },
            error => {
                console.error(error);
            }
        );
    }

    private getUserConfig(resolve: (value: boolean) => void): Promise<boolean> {
        return new Promise((resolveUser) => {
            this.userService?.getMySelf().subscribe(
                response => {
                    if (response.status >= 0) {
                        this._user = UserHelper.buildFromJSON(response.data);
                        localStorage.setItem('user', btoa(JSON.stringify(this._user)));
                        if (this._user.allActions) {
                            this.getRenderConfigByRole();
                            resolveUser(true);

                        } else {
                            resolve(true);
                        }
                    }
                }
            );
        });
    }

    private fillProperties(resolve: (value: boolean) => void) {
        this.propertyService?.getAll('?search=publicProperty=true-B').subscribe(
            response => {
                if (response.status >= 0) {
                    if (response.data.length > 0) {
                        for (let index = 0; index < response.data.length; index++) {
                            const property = PropertyHelper.buildFromJSON(response.data[index]);
                            if (property.key != null && property.value != null) {
                                localStorage.setItem(property.key, property.value);
                            } else if (property.key != null && property.largeValue != null) {
                                localStorage.setItem(property.key, property.largeValue);
                            }
                        }
                        this.getSkin();
                        this.getLangs(resolve);
                        this.setUpInactivityService();
                    }
                }
            },
            error => {
                console.error(error);
                resolve(true);
            }
        );
    }
    private getRenderConfigByRole() {
        this._httpClient.get('assets/config/role/role.json').subscribe(
            response => {
                const roleJSON = this.resolveActions(response);
                this.data.setJsonLevel(DataKeysEnum.ROLE, roleJSON);
            }
        );
    }
    private getValidationsConfig() {
        this._httpClient.get('assets/config/validations/validations.json').subscribe(
            response => {
                const validations = this.resolveActions(response);
                this.data.setJsonLevel(DataKeysEnum.VALIDATIONS, validations);
            }
        );
    }
    private resolveActions(inputJSON: any) {
        const input = JSON.stringify(inputJSON);
        let output = JSON.stringify(inputJSON);
        const regexp = new RegExp(/actions\[\'(\w+)+\'\]/g);
        let found;
        while (found = regexp.exec(input)) {
            if (found.index === regexp.lastIndex) {
                regexp.lastIndex++;
            }
            const replStr = found[0];
            const action = found[1];
            let includes;
            if (this.user && this.user.allActions) {
                includes = this.user.allActions.some((value: Action) => value.name === action);
            }
            if (includes) {
                output = output.replace(replStr, 'true');
            } else {
                output = output.replace(replStr, 'false');
            }
        }
        const result = JSON.parse(output, (k: string, v: any) => {
            k = k;
            return v === 'true' ? true : v === 'false' ? false : v;
        });
        return result;
    }
    private getLangs(resolve: (value: boolean) => void) {
        const property = localStorage.getItem('LANGUAGES');
        this._langs = this.parseLanguages(property);
        resolve(true);
    }
    private parseLanguages(obj: string | null) {
        let result: string[] = [];
        if (obj) {
            const list = obj.split(',');
            for (const prop of list) {
                const lang: any = {};
                lang.code = prop;
                lang.flag = 'assets/images/' + prop + '.svg';
                result.push(lang);
            }
        }
        return result;
    }
    private getVersion() {
        this._httpClient.get('assets/Properties.properties', { responseType: 'text' }).subscribe(
            response => {
                const resp = response;
                const idx = resp.indexOf('=');
                this._version = resp.substring(idx + 1);
            }
        );
    }
    private getBaseLanguagues(resolve: (value: boolean) => void) {
        this.languageService?.getAll('?search=active=true-B').subscribe(
            response => {
                if (response.status === 0 && response.data && response.data instanceof Array) {
                    this._languages = response.data.map((value: any) => LanguageHelper.buildFromJSON(value));
                }
            },
            error => {
                if (error.status === 403) {
                    this.loaded = false;
                    resolve(false);
                }
            }
        );
    }

    private getSkin() {
        const property = localStorage.getItem('BASE_SKIN');
        const defaultValue = 'default';
        const value: string = this.isValidSkin(property) ? property?.substring(0, property.indexOf('-skin')) ?? defaultValue : defaultValue;
        this.themeService.current = value;
    }

    private isValidSkin(property: string | null): boolean {
        return property !== null && property !== '' && property.includes('-skin');
    }

    private setUpInactivityService() {
        const authenticationService = new AuthenticationService(this._httpClient, this.injector);
        this.inactivityService.authenticationService = authenticationService;
        this.inactivityService.setUp();
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard, HomeGuard, PropertyService, OpenGuard } from '@madrija/base-ui/common';
import { AppConfig, FollowUpHelper, ImportType, OperationHelper } from '@madrija/crm-ui/common';
import { ProcedureHelper as EpProcedureHelper } from '@madrija/electro-ui/common';
import { ProcedureHelper as SihProcedureHelper, FollowUpHelper as SihFollowUpHelper } from '@madrija/sih-ui/common';
import { EcgTestHelper } from '@madrija/ecg-ui/common';
import { ProcedureHelper as VasProcedureHelper, FollowupHelper as VasFollowUpHelper } from '@madrija/vascular-ui/common';
import { TacCardioHelper, EcoCardioHelper, RmCardioHelper } from '@madrija/cardio-image-ui/common';
import { AppComponent } from './app.component';
import { AppointmentsImplComponent } from './components/appointments-impl/appointments-impl.component';
import { CrCaseClosureVisitHelper, CrFirstVisitHelper, CrFollowUpHelper, CrNutritionistVisitHelper, CrPharmacy, CrPreProgramHelper, CrPsychologistVisitHelper, CrTrainingSessionHelper } from '@madrija/cardiorehab-ui/common';
import { WaitingListImplComponent } from './components/waiting-list-impl/waiting-list-impl.component';

const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'index', component: AppComponent, canActivate: [HomeGuard] },
  { path: 'home', loadChildren: () => import('@madrija/base-ui/home').then(m => m.HomeModule), data: { noHeader: true }, canActivate: [AuthenticationGuard] },
  { path: 'appointments', component: AppointmentsImplComponent, data: { reuse: true }, canActivate: [AuthenticationGuard] },
  { path: 'appointments/:patientView', component: AppointmentsImplComponent, canActivate: [AuthenticationGuard] },
  { path: 'masters/base', loadChildren: () => import('@madrija/base-ui/masters').then(m => m.MastersModule), canActivate: [AuthenticationGuard] },
  { path: 'errors', loadChildren: () => import('@madrija/base-ui/errors').then(m => m.ErrorsModule), data: { noHeader: true, noFooter: true } },
  { path: 'queries', loadChildren: () => import('@madrija/base-ui/queries-components').then(m => m.QueriesComponentsModule), canActivate: [AuthenticationGuard] },
  { path: 'masters/medical', loadChildren: () => import('@madrija/medical-ui/masters').then(m => m.MastersModule), canActivate: [AuthenticationGuard] },
  { path: 'login', loadChildren: () => import('@madrija/base-ui/login').then(m => m.LoginModule), data: { noHeader: true } },
  { path: 'profile', loadChildren: () => import('@madrija/base-ui/profile').then(m => m.ProfileModule), canActivate: [AuthenticationGuard] },
  { path: 'open', loadChildren: () => import('@madrija/base-ui/open').then(m => m.OpenModule), data: { noHeader: true }, canActivate: [OpenGuard] },
  { path: 'masters/materials', loadChildren: () => import('@madrija/materials-ui/masters').then(m => m.MastersModule), canActivate: [AuthenticationGuard] },
  { path: 'waiting-list', component: WaitingListImplComponent, canActivate: [AuthenticationGuard] },

  {
    path: 'views', canActivate: [AuthenticationGuard],
    data: {
      sticky: '40px', eventsConfig: {
        'CrmFollowUp': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/crm.svg', orderTypeIcon: { icon: 'f0f1', iconColor: '#16c98d' }, helper: FollowUpHelper, route: 'views/crm/follow/:id/dashboard' },
        'CrmOperation': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/crm.svg', orderTypeIcon: { icon: 'f7b9', iconColor: '#ffc83f' }, helper: OperationHelper, route: 'views/crm/operation/:id/dashboard' },
        'EpProcedure': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/ep.svg', orderTypeIcon: { icon: 'f7b9', iconColor: '#ffc83f' }, helper: EpProcedureHelper, route: 'views/ep/procedure/:id/clinic' },
        'SihProcedure': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/sih.svg', orderTypeIcon: { icon: 'f7b9', iconColor: '#ffc83f' }, helper: SihProcedureHelper, route: 'views/sih/procedure/:id/dynamicRecord' },
        'SihFollowUp': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/sih.svg', orderTypeIcon: { icon: 'f0f1', iconColor: '#16c98d' }, helper: SihFollowUpHelper, route: 'views/sih/followup/:id/generalData' },
        'CimEcoCardio': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cim.svg', orderTypeIcon: { imageSrc: 'assets/images/order-types/eco.svg', iconColor: '#6ba5d7' }, helper: EcoCardioHelper, route: 'views/cim/ecocardio/:id/clinic' },
        'CimTACCardio': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cim.svg', orderTypeIcon: { imageSrc: 'assets/images/order-types/tac.svg', iconColor: '#6ba5d7' }, helper: TacCardioHelper, route: 'views/cim/tac/:id/clinic' },
        'CimRMCardio': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cim.svg', orderTypeIcon: { imageSrc: 'assets/images/order-types/reso.svg', iconColor: '#6ba5d7' }, helper: RmCardioHelper, route: 'views/cim/rm/:id/clinic' },
        'EcgTest': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/ecg.svg', orderTypeIcon: { icon: 'f0f1', iconColor: '#16c98d' }, helper: EcgTestHelper, route: 'views/ecg/test/:id/viewer' },
        'CrFirstVisit': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cr.svg', orderTypeIcon: { icon: 'e50d', iconColor: '#16c98d' }, helper: CrFirstVisitHelper, route: 'views/cardiorehab/firstvisit/:id/clinic' },
        'CrTrainingSession': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cr.svg', orderTypeIcon: { icon: 'f2f2', iconColor: '#e69138' }, helper: CrTrainingSessionHelper, route: 'views/cardiorehab/trainingsession/:id/session' },
        'CrPreProgram': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cr.svg', orderTypeIcon: { icon: 'f304', iconColor: '#16c98d' }, helper: CrPreProgramHelper, route: 'views/cardiorehab/pre-program/:id/evaluation' },
        'CrPharmacy': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cr.svg', orderTypeIcon: { icon: 'f485', iconColor: '#16c98d' }, helper: CrPharmacy, route: 'views/cardiorehab/pharmacy/:id/pharmacy' },
        'CrNutritionistVisit': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cr.svg', orderTypeIcon: { icon: 'e2cd', iconColor: '#16c98d' }, helper: CrNutritionistVisitHelper, route: 'views/cardiorehab/nutritionistvisit/:id/nutritionistVisit' },
        'CrPsychologistVisit': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cr.svg', orderTypeIcon: { icon: 'f086', iconColor: '#16c98d' }, helper: CrPsychologistVisitHelper, route: 'views/cardiorehab/psychologistvisit/:id/psychologistVisit' },
        'CrCaseClosureVisit': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cr.svg', orderTypeIcon: { icon: 'f304', iconColor: '#16c98d' }, helper: CrCaseClosureVisitHelper, route: 'views/cardiorehab/case-closure-visit/:id/evaluation' },
        'CrFollowUp': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/cr.svg', orderTypeIcon: { icon: 'f0f1', iconColor: '#16c98d' }, helper: CrFollowUpHelper, route: 'views/cardiorehab/followup/:id/evaluation' },
        'VasProcedure': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/vascular.svg', orderTypeIcon: { icon: 'f7b9', iconColor: '#ffc83f' }, helper: VasProcedureHelper, route: '' },
        'VasFollowUp': { titlePrefix: 'ORDER', moduleSrc: 'assets/images/modules/vascular.svg', orderTypeIcon: { icon: 'f0f1', iconColor: '#16c98d' }, helper: VasFollowUpHelper, route: '' },
        'SURVEY': { titlePrefix: 'CLINICALEVENT', icon: 'f1ea', iconColor: '#8abee5', type: '', clazz: 'not-clickable' },
        'LOST_TRANSMISSION': { titlePrefix: 'CLINICALEVENT', moduleSrc: 'assets/images/lost-transmission.svg', iconColor: '#343a40', type: '', clazz: 'not-clickable' },
        'FA_DEBUT': { titlePrefix: 'CLINICALEVENT', icon: 'f83e', iconColor: '#288ad6', type: '', clazz: 'not-clickable' },
        'DIAGNOSTIC_PROCEDURE': { titlePrefix: 'CLINICALEVENT', icon: 'f022', iconColor: '#8abee5', type: '', clazz: 'not-clickable' },
        'IOT_MEASUREMENT': { titlePrefix: 'CLINICALEVENT', icon: 'f012', iconColor: '#1e3050', type: '', clazz: 'not-clickable' },
        importTypeConfig: ImportType
      }, sendOruPath: 'crm/followup/hl7/', saveReportPath: 'crm/followup/report/save/', medicalViewsUrl: 'views/medical'
    },
    children: [
      { path: 'base', loadChildren: () => import('@madrija/base-ui/views').then(m => m.ViewsModule) },
      { path: 'medical', loadChildren: () => import('@madrija/medical-ui/views').then(m => m.ViewsModule) },
      { path: 'crm', loadChildren: () => import('@madrija/crm-ui/views').then(m => m.ViewsModule) },
      { path: 'ep', loadChildren: () => import('@madrija/electro-ui/views').then(m => m.ViewsModule) },
      { path: 'sih', loadChildren: () => import('@madrija/sih-ui/views').then(m => m.ViewsModule) },
      { path: 'cim', loadChildren: () => import('@madrija/cardio-image-ui/views').then(m => m.ViewsModule) },
      { path: 'ecg', loadChildren: () => import('@madrija/ecg-ui/views').then(m => m.ViewsModule) },
      { path: 'etl', loadChildren: () => import('@madrija/etl-ui/views').then(m => m.ViewsModule) },
      { path: 'vascular', loadChildren: () => import('@madrija/vascular-ui/views').then(m => m.ViewsModule) },
      { path: 'cardiorehab', loadChildren: () => import('@madrija/cardiorehab-ui/views').then(m => m.ViewsModule) }
    ]
  },
  { path: '**', redirectTo: 'errors/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [HomeGuard, PropertyService, AppConfig, OpenGuard]
})
export class AppRoutingModule { }


import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { DeleteDialogComponent } from '@madrija/angular-base/layout';
import { BasicHelper, TranslateRootPipe } from '@madrija/angular-base/common';
import { BasetableComponent, Column, ColumnType } from '@madrija/angular-base/tables';
import { ToastService } from '@madrija/angular-base/toast';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

export class IcsAppReason {
  pid: number | null = null;
  idAppReason: string | null = null;
  textAppReason: string | null = null;
  codeAppReason: string | null = null;
  dateAppReason: string | null = null;
  textService: string | null = null;
}

@Component({
  selector: 'app-integration-aux',
  templateUrl: './integration-aux.component.html',
  styleUrls: ['./integration-aux.component.scss'],
  providers: []
})
export class IntegrationAuxComponent {

  public chpaceMsg: string = '';
  public modalRef: NgbModalRef | null = null;
  public cardModalRef: NgbModalRef | null = null;
  public tableData: any[] = [];
  public columns: Column[] = [];
  public selectionMode: 'multiple' | 'single' = 'single';
  public tableModalTitle: string = 'TIMELINE.SelectEpisode';
  public tableModalFn: Function = this.selectEpisode;

  @ViewChild('sureSendSMS', { static: true }) sureSendSMS: DeleteDialogComponent | null = null;
  @ViewChild('toastTemplate', { static: true }) toastTemplate: TemplateRef<any> | null = null;
  @ViewChild('chpaceMsgDlg', { static: true }) chpaceMsgDlg: TemplateRef<any> | null = null;
  @ViewChild('tableModal', { static: true }) tableModal: TemplateRef<any> | null = null;
  @ViewChild('table', { static: false }) table: BasetableComponent | null = null;
  @ViewChild('printCardTemplate', { static: true }) printCardTemplate: TemplateRef<any> | null = null;

  @Output() callFunction: EventEmitter<{ fn: string, params?: any[] }> = new EventEmitter();

  constructor(private modalService: NgbModal, private toastr: ToastService, private translateService: TranslateService, private translateRootPipe: TranslateRootPipe) {
    this.createColumns()
  }

  toastClick(event: any) {
    event.stopPropagation();
    this.modalService.open(this.chpaceMsgDlg, { scrollable: true })
  }

  showModalTemplate(chpaceMsg: string, title: string) {
    this.chpaceMsg = chpaceMsg;
    this.toastr.show(chpaceMsg, title, { autohide: false, classname: 'toast-danger long-text-toast', icon: 'fa fa-exclamation-circle', template: this.toastTemplate })
  }

  notEpisodeIDModal(data: any) {
    let arrayData = JSON.parse(data);
    const columnsData: any[] = [];
    arrayData.forEach((value: any) => {
      columnsData.push(value.textData);
    });
    var changeID = columnsData.map(item => {
      var temporal = item.id;
      delete item.id;
      item.pid = temporal;
      return item;
    });
    this.modalRef = this.modalService.open(this.tableModal);
    this.tableData = changeID;
  }

  selectEpisode() {
    const episode = this.table?.selection;
    const placerOrder = episode.idPrestacion;
    this.callFunction.emit({ fn: 'savePlaceOrder', params: [placerOrder] });
  }

  showConfirmationPrintModal() {
    this.cardModalRef = this.modalService.open(this.printCardTemplate);
  }

  printCard() {
    this.callFunction.emit({ fn: 'printDeviceCard' });
  }

  selectIcsAppReason() {
    if (this.table?.selection?.length) {
      this.modalRef?.close();
      this.callFunction.emit({ fn: 'send_oru_appreason', params: [this.table.selection.map((data: IcsAppReason) => data.idAppReason)] });
    } else {
      this.toastr.warning(this.translateRootPipe.transform('IcsAppReason.ShouldSelectOne'), this.translateRootPipe.transform('IcsAppReason.SelectAppReason'))
    }
  }

  showIcsAppReasonModal(data: Array<any>) {
    this.columns = Object.keys(new IcsAppReason).map<Column>((key: string) => { return { field: key, header: this.translateRootPipe.transform(`IcsAppReason.${key}`), type: key.includes('date') ? ColumnType.DATE : ColumnType.STRING, showfilter: false, show: key !== 'pid' } });
    this.tableData = data.map((d: any) => { d.pid = Math.random() * 100 + 1; const result = BasicHelper.buildFromJson(new IcsAppReason, d); result.dateAppReason = new Date(result.dateAppReason); return result; });
    this.selectionMode = 'multiple';
    this.tableModalTitle = this.translateRootPipe.transform('IcsAppReason.SelectAppReason');
    this.tableModalFn = this.selectIcsAppReason;
    this.modalRef = this.modalService.open(this.tableModal, { size: 'lg' });
  }

  private createColumns() {
    this.translateService.get(['TIMELINE.Date', 'TIMELINE.Id', 'TIMELINE.Service', 'TIMELINE.Description']).subscribe(translations => {
      this.columns = [
        { field: 'startDate', header: translations['TIMELINE.Date'], type: ColumnType.STRING, showfilter: false },
        { field: 'idPrestacion', header: translations['TIMELINE.Id'], type: ColumnType.STRING, showfilter: false },
        { field: 'servicioAdmision', header: translations['TIMELINE.Service'], type: ColumnType.STRING, showfilter: false },
        { field: 'descripcionServicio', header: translations['TIMELINE.Description'], type: ColumnType.STRING, showfilter: false }
      ];
    });
  }

}
import { Injectable } from "@angular/core";
import { Route } from "@angular/router";
import { Router, Routes } from "@angular/router";
import { OpenAbstractFactory } from "@madrija/base-ui/common";
import { OrderService, PatientService } from "@madrija/medical-ui/common";

export enum OpenEnum {
  PATIENT = 'patient',
  ORDER = 'order',
  PRIMARY_PID = 'patient-primary-pid',
}

@Injectable()
export class OpenFactory extends OpenAbstractFactory {

  constructor(private router: Router, private orderService: OrderService, private patientService: PatientService) {
    super();
  }

  public navigateTo(pattern: string, id: number) {
    let route = 'errors/404';
    switch (pattern) {
      case OpenEnum.ORDER:
        this.getOrderInfo(id).then((value: {dtype: string} | null) =>{
          if(value) {
            route = this.routeByDtype(value.dtype) ?? route;
            route = this.processRoute(route, id);
          }
          this.navigate(route);
        });
        break;
      case OpenEnum.PATIENT:
          this.navigate(`/views/medical/patientView/${id}/general`);
        break;
      case OpenEnum.PRIMARY_PID:
        this.getPatientInfo(id).then((value: {id: number} | null) =>{
          if(value) route = `/views/medical/patientView/${value.id}/general`;
          this.navigate(route);
        });
        break;
      default:
        this.navigate(route);
        break;
      }
  }

  private navigate(route: string) {
    this.router.navigate([route]);
  }

  private routeByDtype(dtype: string): string {
    const viewsConfig: Route | undefined = (this.router.config as Routes).find((value: Route) => value.path === 'views');
    if(viewsConfig) {
      const config = viewsConfig.data?.['eventsConfig'];
      if(config && config[dtype]) {
        return config[dtype].route;
      }
    }
    return '';
  }

  private processRoute(route: string, id: number) {
    return route.replace(':id', id.toFixed());
  }

  private getOrderInfo(id: number): Promise<{dtype: string} | null> {
    return new Promise(resolve => {
      this.orderService.getDtypeByPid(id).subscribe(
        response => {
          if(response.status === 0) {
            resolve({dtype: response.data});
          } else  {
            resolve(null);
          }
        },
        error => {
          resolve(null);
        }
      );
    });
  }

  private getPatientInfo(id: number): Promise<{id: number} | null> {
    return new Promise(resolve => {
      this.patientService.getPidByProvider(id).subscribe(
        response => {
          if(response.status === 0) {
            resolve({id: response.data});
          } else  {
            resolve(null);
          }
        },
        error => {
          resolve(null);
        }
      );
    });
  }
}

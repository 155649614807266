import { Component, ViewChild, AfterViewInit, EventEmitter, Renderer2 } from '@angular/core';
import { Appointment, AppointmentHelper, Order } from '@madrija/medical-ui/common';
import { VendorService } from '@madrija/materials-ui/common';
import { TranslateService } from '@ngx-translate/core';
import { HTMLHelper, MabHTMLElement, BaseComponent } from '@madrija/angular-base/common';
import { DeleteDialogComponent } from '@madrija/angular-base/layout';
import { ToastService } from '@madrija/angular-base/toast';
import { AppointmentsComponent } from '@madrija/medical-ui/shared-components';
import { OperationType } from '@madrija/crm-ui/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CvisAppointment } from 'src/app/models/cvis-appointment.model';
import { Tooltip } from 'src/app/models/tooltip.model';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-appointments-impl',
  templateUrl: './appointments-impl.component.html',
  styleUrls: ['./appointments-impl.component.scss'],
  providers: [VendorService]
})
export class AppointmentsImplComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('appointments', { static: true }) appointments: AppointmentsComponent | null = null;
  @ViewChild('saveDlg', { static: false }) dialogo: DeleteDialogComponent | null = null;
  appointmentToSave: Appointment | null = null;
  dtoMapper = CvisAppointment.dtoMapper();
  lastReuseComponentCallTime: Date | null = null;
  constructor(private translate: TranslateService, private renderer: Renderer2, public override modalService: NgbModal, public override toastr: ToastService, public vendorService: VendorService,
    private location: Location, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute) {
    super(toastr, translate, modalService);
    router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationEnd) {
        const cleanUrl = router.url.indexOf('?') > -1 ? router.url.substring(0, router.url.indexOf('?')) : router.url;
        const areSame = cleanUrl === '/'.concat(this.route.snapshot.url[0].path);
        if (areSame) {
          if (this.appointments && !this.appointments.refreshInterval) this.appointments.createRefreshInterval();
        } else {
          this.appointments?.clearInterval();
        }
      }
    });
  }
  ngAfterViewInit(): void {
    if (this.appointments && this.appointments.splitPanel) this.appointments.splitPanel.fullScreenBtnClick.subscribe(() => setTimeout(() => this.configAppointments(), 200));
    this.configAppointments();
  }

  createEvent = (appointment: Appointment) => {
    const obj = new CvisAppointment(appointment);
    return {
      id: obj.pid,
      start: obj.start,
      end: obj.end,
      title: (obj.patient) ? obj.patient.toString() : '',
      classNames: obj.styleClass(),
      extendedProps: {
        scheduleColor: obj.getScheduleColor(),
        roomColor: obj.getRoomColor(),
        finalized: obj.isFinalized(),
        canceled: obj.isCanceled(),
        imported: obj.isImported(),
        importedIcon: obj.importIcon(),
        orderType: obj.getOrderType(),
        moduleSrc: obj.getModuleSrc(),
        orderTypeIcon: obj.orderTypeIcon(),
        orderTypeSrc: obj.getOrderTypeSrc(),
        operationType: obj.operationType(),
        operationTypeIcon: obj.operationTypeIcon(),
        cameralType: obj.cameralType(),
        cameralTypeIcon: obj.cameralTypeIcon(),
        updateDate: obj.updateDate,
        conflict: obj.conflict(),
        patientStatusInOrder: obj.getPatientStatusInOrder(),
        otherComments: obj.getOtherComments()
      },
      editable: this.appointments ? this.appointments.isEditableEvent(obj) : true
    };
  }

  eventRender = (info: any, _isList: boolean) => {
    const isList = info.view.type === 'listDay';
    if (isList) {
      this.renderList(info);
    } else {
      this.renderNotList(info);
    }
  }

  onReuseComponent() {
    if ((this.lastReuseComponentCallTime !== null && (new Date().getTime() - this.lastReuseComponentCallTime.getTime()) < 2000)) return;
    this.lastReuseComponentCallTime = new Date()
    const params = new URLSearchParams();
    if (this.appointments?.calendar) {
      if (this.appointments.calendar?.defaultView) {
        const defaultView = this.appointments.calendar.defaultView;
        const value = Object.keys(this.appointments.calendar.viewsQuery).find(key => this.appointments?.calendar?.viewsQuery[key] === defaultView);
        if (value) params.append('view', value);
      }
      const date = this.appointments.calendar?.calendarComponent?.getApi().getDate();
      const stringDate = this.datePipe.transform(date, 'dd-MM-yyyy');
      if (stringDate) params.append('date', stringDate)
    }

    this.appointments?.manageScheduleParamValue(params);
    const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: this.urlParamsToObject(params) }).toString();
    this.location.go(url);
    setTimeout(() => { this.appointments?.calendar?.calendarComponent?.getApi().scrollToTime(this.appointments.startTime); this.appointments?.refreshEvents(); }, 200);
  }

  private renderNotList(info: any) {
    const childrenEvent = [].slice.call(info.el.children);
    const floatContainer = childrenEvent.find((child: any) => child.classList && child.classList.value.includes('float-icons'));

    this.renderer.appendChild(info.el, floatContainer);
    if (info.event.extendedProps.imported && !info.event.extendedProps.canceled) {
      const importIconClasses: string = info.event.extendedProps.importedIcon;
      const icons = importIconClasses.split(' ');
      let styles;
      if (importIconClasses === 'ebox') {
        styles = [{ style: 'background-image', value: 'url(assets/images/ico-ebox@2x.png)' }];
      }
      const importedIcon: MabHTMLElement = { tag: 'i', classes: ['rounded-icon', ...icons], styles: styles };
      const i = HTMLHelper.createElement(this.renderer, importedIcon);
      this.renderer.appendChild(floatContainer, i);
    }
    let fcContent;
    if (info.view.type === 'dayGridMonth') {
      fcContent = info.el;
    } else {
      const children = [].slice.call(info.el.children);
      fcContent = children.find((child: any) => child.classList && child.classList.value.includes('fc-event-main'));
    }
    const elementContent: MabHTMLElement = { tag: 'div', classes: ['content-icons'] };
    const divContent = HTMLHelper.createElement(this.renderer, elementContent);
    if (fcContent) this.renderer.insertBefore(fcContent, divContent, fcContent.children[0]);
    const orderTypeIcon = info.event.extendedProps.orderTypeIcon;
    const orderTypeSrc = info.event.extendedProps.orderTypeSrc;
    const src = info.event.extendedProps.moduleSrc;
    if (orderTypeIcon || orderTypeSrc) {
      if (orderTypeIcon) {
        const orderTypeIconClasses: string = orderTypeIcon;
        const icons = orderTypeIconClasses.split(' ');
        const orderIcon: MabHTMLElement = { tag: 'i', classes: ['text-dark', ...icons] };
        const i = HTMLHelper.createElement(this.renderer, orderIcon);
        this.renderer.appendChild(divContent, i);
      }
      else if (orderTypeSrc) {
        const orderTypeSvg: MabHTMLElement = { tag: 'img', classes: ['mb-2'], properties: [{ property: 'src', value: orderTypeSrc }, { property: 'width', value: '14' }, { property: 'height', value: '14' }] }
        const svg = HTMLHelper.createElement(this.renderer, orderTypeSvg);
        this.renderer.appendChild(divContent, svg);
      }
      if (src) {
        const moduleSvg: MabHTMLElement = { tag: 'img', classes: ['float-start', 'mb-2', 'me-1'], properties: [{ property: 'src', value: src }, { property: 'width', value: '14' }, { property: 'height', value: '14' }] }
        const svg = HTMLHelper.createElement(this.renderer, moduleSvg);
        this.renderer.appendChild(divContent, svg);
      }
      if (info.event.extendedProps.orderType === 'CrmOperation') {
        if ((info.event.extendedProps.operationType && info.event.extendedProps.operationType.length > 0) || info.event.extendedProps.cameralType !== '') {
          const operationTypeTag: MabHTMLElement = {
            tag: 'span',
            properties: [{ property: 'innerHTML', value: info.event.extendedProps.operationTypeIcon }]
          };
          const spanOrderType = HTMLHelper.createElement(this.renderer, operationTypeTag);
          this.renderer.appendChild(divContent, spanOrderType);
          if (info.event.extendedProps.cameralType !== '') {
            const cameralTypeTag: MabHTMLElement = {
              tag: 'span', classes: ['sub'],
              properties: [{ property: 'innerHTML', value: info.event.extendedProps.cameralTypeIcon }]
            };
            const spanCameralType = HTMLHelper.createElement(this.renderer, cameralTypeTag);
            this.renderer.appendChild(spanOrderType, spanCameralType);
          }
        }
      }
    }
  }
  private renderList(info: any) {
    const children = [].slice.call(info.el.children);
    const listItemTitle: any = children.find((child: any) => child.classList && child.classList.value.includes('fc-list-event-title'));
    if (listItemTitle) {
      // div container for otherComments
      const otherComments = info.event.extendedProps.otherComments;
      if (otherComments) {
        const comment: MabHTMLElement = { tag: 'div', classes: ['comments'] };
        const div = HTMLHelper.createElement(this.renderer, comment);
        const commentsIcon: MabHTMLElement = { tag: 'i', classes: ['fa-solid', 'fa-comment', 'fa-lg', 'me-2'] };
        const i = HTMLHelper.createElement(this.renderer, commentsIcon);
        const spanComment: MabHTMLElement = { tag: 'span', classes: ['comments-content'], properties: [{ property: 'innerHTML', value: otherComments }] };
        const span = HTMLHelper.createElement(this.renderer, spanComment);
        this.renderer.appendChild(div, i);
        this.renderer.appendChild(div, span);
        this.renderer.appendChild(listItemTitle, div);
      }
      const childrenTitle = [].slice.call(listItemTitle.children);
      const iconsContainer = childrenTitle.find((child: any) => child.classList && child.classList.value.includes('right-icons'));
      if (info.event.extendedProps.imported) {
        const importIconClasses: string = info.event.extendedProps.importedIcon;
        const icons = importIconClasses.split(' ');
        let styles;
        if (importIconClasses === 'ebox') {
          styles = [{ style: 'background-image', value: 'url(assets/images/ico-ebox@2x.png)' }];
        }
        const importedIcon: MabHTMLElement = { tag: 'i', classes: [...icons], styles: styles };
        const i = HTMLHelper.createElement(this.renderer, importedIcon);
        this.renderer.appendChild(iconsContainer, i);
      }
      const orderTypeIcon = info.event.extendedProps.orderTypeIcon;
      const orderTypeSrc = info.event.extendedProps.orderTypeSrc;
      const src = info.event.extendedProps.moduleSrc;
      if (orderTypeIcon || orderTypeSrc) {
        if (src) {
          const moduleSvg: MabHTMLElement = { tag: 'img', classes: ['mb-2', 'ms-1'], properties: [{ property: 'src', value: src }, { property: 'width', value: '14' }, { property: 'height', value: '14' }] }
          const svg = HTMLHelper.createElement(this.renderer, moduleSvg);
          this.renderer.appendChild(iconsContainer, svg);
        }
        if (orderTypeIcon) {
          const orderTypeIconClasses: string = orderTypeIcon;
          const icons = orderTypeIconClasses.split(' ');
          const orderIcon: MabHTMLElement = { tag: 'i', classes: ['text-dark', ...icons] };
          const i = HTMLHelper.createElement(this.renderer, orderIcon);
          this.renderer.appendChild(iconsContainer, i);
        }
        else if (orderTypeSrc) {
          const orderTypeSvg: MabHTMLElement = { tag: 'img', classes: ['mb-2', 'ms-1'], properties: [{ property: 'src', value: orderTypeSrc }, { property: 'width', value: '14' }, { property: 'height', value: '14' }] }
          const svg = HTMLHelper.createElement(this.renderer, orderTypeSvg);
          this.renderer.appendChild(iconsContainer, svg);
        }
        if (info.event.extendedProps.orderType === 'CrmOperation') {
          if (info.event.extendedProps.operationType && info.event.extendedProps.operationType.length > 0) {
            const operationTypeTag: MabHTMLElement = {
              tag: 'span',
              properties: [{ property: 'innerHTML', value: info.event.extendedProps.operationTypeIcon }]
            };
            const spanOrderType = HTMLHelper.createElement(this.renderer, operationTypeTag);
            this.renderer.appendChild(iconsContainer, spanOrderType);
            if (info.event.extendedProps.cameralType !== '') {
              const cameralTypeTag: MabHTMLElement = {
                tag: 'span', classes: ['sub'],
                properties: [{ property: 'innerHTML', value: info.event.extendedProps.cameralTypeIcon }]
              };
              const spanCameralType = HTMLHelper.createElement(this.renderer, cameralTypeTag);
              this.renderer.appendChild(spanOrderType, spanCameralType);
            }
          }
        }
      }
    }
  }
  createTooltip = (info: any) => {
    const tooltip = new Tooltip(this.translate, this.vendorService);
    const obj = this.appointments ? this.appointments.appointmentList.find(app => app.pid === Number(info.event.id)) : null;
    tooltip.eventTs = (obj) ? new CvisAppointment(obj) : info;
    return tooltip;
  }
  customOrderType = (appointment: Appointment) => {
    const obj = new CvisAppointment(appointment);
    return obj.getOrderType();
  }
  translateOrderType = (type: string) => {
    let result = '';
    switch (type) {
      case 'CrmOperation':
        result = this.translate.instant('ORDER.Operation');
        break;
      case 'CrmFollowUp':
        result = this.translate.instant('ORDER.FollowUp');
        break;
      case 'EpProcedure':
        result = this.translate.instant('ORDER.EpProcedure');
        break;
      case 'SihProcedure':
        result = this.translate.instant('ORDER.SihProcedure');
        break;
      case 'SihFollowUp':
        result = this.translate.instant('ORDER.SihFollowUp');
        break;
      case 'EcgTest':
        result = this.translate.instant('ORDER.EcgTest');
        break;
      case 'CimEcoCardio':
        result = this.translate.instant('ORDER.EcoCardio');
        break;
      case 'CimTACCardio':
        result = this.translate.instant('ORDER.TAC');
        break;
      case 'CimRMCardio':
        result = this.translate.instant('ORDER.RM');
        break;
      case 'CrFirstVisit':
        result = this.translate.instant('ORDER.FirstVisit');
        break;
      case 'CrPreProgram':
        result = this.translate.instant('ORDER.PreProgram');
        break;
      case 'CrCaseClosureVisit':
        result = this.translate.instant('ORDER.CaseClosureVisit');
        break;
      case 'CrTrainingSession':
        result = this.translate.instant('ORDER.TrainingSession');
        break;
      case 'CrPharmacy':
        result = this.translate.instant('ORDER.Pharmacy');
        break;
      case 'CrNutritionistVisit':
        result = this.translate.instant('ORDER.NutritionistVisit');
        break;
      case 'CrPsychologistVisit':
        result = this.translate.instant('ORDER.PsychologistVisit');
        break;
      case 'CrFollowUp':
        result = this.translate.instant('ORDER.FollowUp');
        break;
      case 'SihProcedure':
        result = this.translate.instant('ORDER.SihProcedure');
        break;
      case 'SihFollowUp':
        result = this.translate.instant('ORDER.SihFollowUp');
        break;
    }
    return result;
  }

  whereToNavigate = (appointment: Appointment) => {
    const obj = new CvisAppointment(appointment);
    const order = obj.getOrder();
    const pid = order ? order.pid : 0;
    let url = '';
    switch (obj.getOrderType()) {
      case 'CrmOperation':
        const tab = (obj.isFinalized() === false &&
          obj.operationType() && obj.operationType().length > 0 && obj.operationType().includes(OperationType.FIRSTIMPLANT)) ? '/device' : '/dashboard';
        url = 'views/crm/operation/' + pid + tab;
        break;
      case 'CrmFollowUp':
        url = 'views/crm/follow/' + pid + '/dashboard';
        break;
      case 'EpProcedure':
        url = 'views/ep/procedure/' + pid + '/procedure';
        break;
      case 'SihProcedure':
        url = 'views/sih/procedure/' + pid + '/dynamicRecord';
        break;
      case 'SihFollowUp':
        url = 'views/sih/followup/' + pid + '/generalData';
        break;
      case 'EcgTest':
        url = 'views/ecg/test/' + pid + '/viewer';
        break;
      case 'CimEcoCardio':
        url = 'views/cim/ecocardio/' + pid + '/clinic';
        break;
      case 'CimTACCardio':
        url = 'views/cim/tac/' + pid + '/clinic';
        break;
      case 'CimRMCardio':
        url = 'views/cim/rm/' + pid + '/clinic';
        break;
      case 'VasProcedure':
        url = 'views/vascular/procedure/' + pid + '/patient';
        break;
      case 'VasFollowUp':
        url = 'views/vascular/follow/' + pid + '/patient';
        break;
      case 'CrFirstVisit':
        url = 'views/cardiorehab/firstvisit/' + pid + '/clinic';
        break;
      case 'CrPsychologistVisit':
        url = 'views/cardiorehab/psychologistvisit/' + pid + '/psychologistVisit';
        break;
      case 'CrTrainingSession':
        url = 'views/cardiorehab/trainingsession/' + pid + '/session';
        break;
      case 'CrFollowUp':
        url = 'views/cardiorehab/followup/' + pid + '/evaluation';
        break;
      case 'CrPreProgram':
        url = 'views/cardiorehab/pre-program/' + pid + '/evaluation';
        break;
      case 'CrCaseClosureVisit':
        url = 'views/cardiorehab/case-closure-visit/' + pid + '/evaluation';
        break;
      case 'CrNutritionistVisit':
        url = 'views/cardiorehab/nutritionistvisit/' + pid + '/nutritionistVisit';
        break;
      case 'CrPharmacy':
        url = 'views/cardiorehab/pharmacy/' + pid + '/pharmacy';
        break;
      default:
        break;
    }
    return url;
  }

  getOrderIconByDtype = (type: string) => {
    const order = new Order;
    order.dtype = type;
    const app = new Appointment;
    app.order = [order];
    const playApp = new CvisAppointment(app);
    return playApp.orderTypeIcon();
  }

  getModuleByDtype = (type: string) => {
    const order = new Order;
    order.dtype = type;
    const app = new Appointment;
    app.order = [order];
    const playApp = new CvisAppointment(app);
    return playApp.orderModule();
  }

  get form() {
    if (this.appointments && this.appointments.appForm) {
      return this.appointments.appForm.appointmentform;
    }
    return;
  }
  get orderType() {
    return this.appointments ? this.appointments.orderType : null;
  }

  cancelSave() {
    this.appointmentToSave = null;
  }

  hasChangeOperation() {
    if (this.form && this.form.valid) {
      const appointment = AppointmentHelper.buildFromFormGroup(this.form);
      const crmApp = new CvisAppointment(appointment);
      if (crmApp.order != null && crmApp.order.length !== 0 && this.orderType &&
        crmApp.getOrderType() !== this.orderType.value && this.orderType.value === 'CrmFollowUp') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  modalSaveButtonClick() {
    this.appointments?.modalSaveButtonClick();
  }
  modalSaveButtonClickCheck() {
    if (this.hasChangeOperation()) {
      if (this.dialogo) this.dialogo.show();
    } else {
      this.appointments?.modalSaveButtonClick();
    }
  }

  private configAppointments() {
    if (this.appointments && this.appointments.calendar) {
      this.appointments.calendar.saveClick = new EventEmitter();
      this.appointments.calendar.saveClick.subscribe(() => {
        this.modalSaveButtonClickCheck();
      });
    }
  }

  private urlParamsToObject(urlParams: URLSearchParams) {
    const params: { [key: string]: string } = {};
    urlParams.forEach((value: string, key: string) => {
      params[key] = value;
    })
    return params;
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrderDtoType, OrderService } from '@madrija/medical-ui/common';
import { BasicHelper } from '@madrija/angular-base/common';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@madrija/angular-base/toast';



@Component({
  selector: 'app-waiting-list-impl',
  templateUrl: './waiting-list-impl.component.html',
  styleUrls: ['./waiting-list-impl.component.scss'],
  providers: [OrderService]
})
export class WaitingListImplComponent implements OnInit {


  public cvisModules = ['Crm', 'Sih', 'Ep', 'Cim', 'Ecg', 'Uc', 'Insufi', 'Cr', 'Vascular'];
  public moduleForm: FormGroup;
  public orderTypes: OrderDtoType[] = [];

  constructor(private formBuilder: FormBuilder, public orderService: OrderService, private translateService: TranslateService,
    public toastService: ToastService) {

    this.moduleForm = this.formBuilder.group({
      module: [null, null]
    });
  }

  ngOnInit(): void {
    this.getOrderTypes();
  }

  public hasModule(module: string) {
    let property = localStorage.getItem('CVIS_LICENSE_MODULES');
    property = property?.replace(' ', '') ?? '';
    const modules = property.split(',');
    return modules.includes(module);
  }

  public isModuleCheck(type: string) {
    return this.moduleForm.controls['module'].value === type;
  }

  private getOrderTypes() {
    this.orderService.getOrderTypes().subscribe(
      (response: any) => {
        if (response.status >= 0) {
          this.orderTypes = [];
          for (const data of response.data) {
            this.orderTypes.push(BasicHelper.buildFromJson(new OrderDtoType, data));
          }
        }
      },
      (error: any) => {
        this.toastService.error(this.translateService.instant('Appointment.appointment'), this.translateService.instant('Appointment.appointment'));
      }
    );
  }

  public getOrderTypesFromModule(module: string) {
    return module == 'Cr' ? this.orderTypes.filter(item => (item.type && item.type.startsWith(module) && !item.type.startsWith('Crm'))) : this.orderTypes.filter(item => (item.type && item.type.startsWith(module)));
  }


}

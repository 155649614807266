import { APP_INITIALIZER, NgModule, LOCALE_ID, Injector, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule, TextEditorService, FilterFavouriteService } from '@madrija/angular-base/layout';
import { ToastModule } from '@madrija/angular-base/toast';
import { BaseTableService, TablesModule } from '@madrija/angular-base/tables';
import { InputModule, AutocompleteService } from '@madrija/angular-base/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimelineService, TimelineHelper, TooltipAbstractFactory } from '@madrija/angular-base/chart';
import { BasicHelper, SessionService, ReuseStrategy, ComponentFactoryService, MadrijaAngularBasePipesModule, InjectorLocator } from '@madrija/angular-base/common';
import { SharedComponentsModule } from '@madrija/medical-ui/shared-components';
import { HealthStaff, PatientRow, PatientHasHealthStaff, ClinicalEvent, MedTimelineHelper, MedTimelineService, TimelineElementAbstractFactory, MedTextEditorTemplateService, OrderAbstractFactory, OrderService, PatientService, VitalSign, ImplantedEquipment, DrugOrder, NurseMeasurement, HealthStaffPosition } from '@madrija/medical-ui/common';
import { Center, Phone, MissingTranslationHandler as MabMissingTranslationHandler, BaseTableConfigService, TokenInterceptor, Locality, Postalcode, OpenAbstractFactory, BaseAutocompleteKVService, MasterReportAbstractFactory, ReportButtonIntegrationAbstractFactory, InactivityService, TableFilterFavouriteService } from '@madrija/base-ui/common';
import { Alert, Model, EpisodeCount, Episode, FollowUpService, OperationService } from '@madrija/crm-ui/common';
import { HttpProgressBarModule } from '@madrija/angular-base/http-progress-bar';
import { AppConfig } from './config/app.config';
import { InstallationConfigModule, InstallationConfigService, APP_CONFIG } from '@madrija/angular-base/installation-config';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouteReuseStrategy } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppointmentFormOrderTemplateComponent } from './components/appointment-order-template/appointment-order-template.component';
import { AppointmentsImplComponent } from './components/appointments-impl/appointments-impl.component';
import { environment } from 'src/environments/environment';
import { CvisComponentFactoryService } from './services/cvis-component-factory.service';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { CvisTimelineElementFactory } from './protocols/cvis-timelineElement.abstractFactory';
import { CvisTooltipAbstractFactory } from './protocols/cvis-tooltip.abstractFactory';
import { sharedComponents as BaseSharedComponents } from '@madrija/base-ui/shared-components'
import { sharedComponents as MatSharedComponents } from '@madrija/materials-ui/shared-components';
import { sharedComponents as MedSharedComponents } from '@madrija/medical-ui/shared-components';
import { sharedComponents as CvSharedComponents } from '@madrija/cardiovascular-ui/shared-components';
import { sharedComponents as CrSharedComponents } from '@madrija/cardiorehab-ui/shared-components';
import { sharedComponents as CrmSharedComponents, SharedComponentsModule as CrmSharedComponentsModule } from '@madrija/crm-ui/shared-components';
import { sharedComponents as SihSharedComponents, SharedComponentsModule as SihSharedComponentsModule } from '@madrija/sih-ui/shared-components';
import { sharedComponents as EpSharedComponents } from '@madrija/electro-ui/shared-components';
import { sharedComponents as EcgSharedComponents } from '@madrija/ecg-ui/shared-components';
import { sharedComponents as CimSharedComponents } from '@madrija/cardio-image-ui/shared-components'
import { LocalService } from '@madrija/crm-ui/common';
import { CvisOrderAbstractFactory } from './protocols/cvis-order-factory.service';
import { dynamicFormSharedComponents as DynamicFormSharedComponents, EnumFactory } from '@madrija/base-ui/dynamic-form';
import { CvisEnumFactoryService } from './protocols/cvis-enum.factory.service';
import { ReporterModule } from '@madrija/reporter';
import { MasterReportFactory } from './protocols/master-report.factory.service';
import { ReportButtonIntegrationFactory } from './protocols/report-integration-button.factory.service';
import { IntegrationAuxComponent } from './components/integration-aux/integration-aux.component';
import { OpenFactory } from './protocols/openFactory.service';
import { VendorService } from '@madrija/materials-ui/common';
import { CvisTimelineHelper } from './helpers/cvisTimeline.helper';
import { InjuryMapImageContainerComponent } from '@madrija/cardiovascular-ui/injury-map-core';
import { WaitingListImplComponent } from './components/waiting-list-impl/waiting-list-impl.component';
import { Balloon, Bridge, Injury, Stent } from '@madrija/cardiovascular-ui/common';
import { DiagnosticRecord } from '@madrija/sih-ui/common';


//Alguno HealthStaff no los detecta como tal y los procesa como si fueran objetos normales
BasicHelper.objClasses = [HealthStaff, Alert, Model, EpisodeCount, Episode, Center, Phone, PatientRow, PatientHasHealthStaff, Locality, Postalcode, ClinicalEvent, VitalSign, ImplantedEquipment, NurseMeasurement, DrugOrder, HealthStaffPosition, Balloon, Stent, Bridge, DiagnosticRecord];
BasicHelper.continue_regex_concurrency = localStorage.getItem('CONTINUE_REGEX_CONCURRENCY') ?? '';
BasicHelper.skip_regex_concurrency = localStorage.getItem('SKIP_REGEX_CONCURRENCY') ?? '';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    AppComponent,
    AppointmentFormOrderTemplateComponent,
    AppointmentsImplComponent,
    IntegrationAuxComponent,
    WaitingListImplComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    ToastModule,
    HttpProgressBarModule,
    InstallationConfigModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MabMissingTranslationHandler,
      }, isolate: true
    }),
    SharedComponentsModule,
    CrmSharedComponentsModule,
    NgSelectModule,
    InputModule,
    MadrijaAngularBasePipesModule.forRoot(),
    NgbNavModule,
    ReporterModule.forRoot([InjuryMapImageContainerComponent]),
    TablesModule,
    SihSharedComponentsModule
  ],
  providers: [
    LocalService,
    { provide: APP_CONFIG, useExisting: AppConfig },
    {
      provide: APP_INITIALIZER, useFactory: (config: AppConfig, service: InstallationConfigService) => () =>
        service.load().then(() => config.load()), deps: [AppConfig, InstallationConfigService, InactivityService], multi: true
    },
    { provide: APP_BASE_HREF, useFactory: getBaseHref, deps: [PlatformLocation] },
    { provide: LOCALE_ID, deps: [SessionService], useFactory: (sessionService: SessionService) => sessionService.locale },
    { provide: 'LOCALE_SUBJECT', deps: [SessionService], useFactory: (sessionService: SessionService) => sessionService.localeSub },
    { provide: RouteReuseStrategy, useClass: ReuseStrategy },
    { provide: 'env', useValue: environment },
    { provide: AutocompleteService, useClass: BaseAutocompleteKVService },
    { provide: BaseTableService, useClass: BaseTableConfigService },
    { provide: ComponentFactoryService, useClass: CvisComponentFactoryService },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: TimelineService, useClass: MedTimelineService },
    { provide: TimelineHelper, useClass: CvisTimelineHelper },
    VendorService,
    { provide: TooltipAbstractFactory, useClass: CvisTooltipAbstractFactory },
    { provide: TimelineElementAbstractFactory, useClass: CvisTimelineElementFactory },
    { provide: TextEditorService, useClass: MedTextEditorTemplateService },
    { provide: OrderAbstractFactory, useClass: CvisOrderAbstractFactory },
    { provide: EnumFactory, useClass: CvisEnumFactoryService },
    { provide: 'ENUM_FACTORY_SERVICE', useClass: CvisEnumFactoryService },
    PatientService, OrderService,
    { provide: OpenAbstractFactory, useClass: OpenFactory },
    { provide: MasterReportAbstractFactory, useClass: MasterReportFactory },
    OrderService, FollowUpService, OperationService,
    { provide: ReportButtonIntegrationAbstractFactory, useClass: ReportButtonIntegrationFactory },
    { provide: FilterFavouriteService, useClass: TableFilterFavouriteService },
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
  protected entryComponents: Type<any>[] = [...BaseSharedComponents, ...MatSharedComponents, ...MedSharedComponents, ...CvSharedComponents, ...CrmSharedComponents, ...CimSharedComponents, ...SihSharedComponents, ...EpSharedComponents, ...EcgSharedComponents, ...CrSharedComponents, ...DynamicFormSharedComponents];
  constructor(private injector: Injector) {    // Create global Service Injector.
    InjectorLocator.injector = this.injector;
  }
}

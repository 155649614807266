
import { KeyValue } from "@madrija/angular-base/common";
import { DeviceCameralType, DeviceType, OperationType } from "@madrija/crm-ui/common";
import { Appointment } from "@madrija/medical-ui/common";

export class CvisAppointmentDTO extends Appointment {
	constructor(appointment: Appointment) {
		super(appointment);
	}
	operationType: OperationType[] | null = null
	cameralType: DeviceCameralType | null = null;
	deviceType: DeviceType | null = null;
	patientOrigin: KeyValue | null = null;
	orderPriority: KeyValue | null = null;
}
<div class="content-wrapper">
	<div class="content-layout">
		<mab-header *ngIf="shouldAppearHeader && isLoaded" [items]="items" #header [badges]="false" [logo]="'assets/images/logo-enigmams.svg'" [routerLinkRoute]="'/index'"
		(onLogout)="logout()" [languages]="languages" (localeChange)="onLocaleChanged($event)" [notifications]="notifications" [help]="help ?? []"
		[versionLabel]="'HEADER.Version' | translate" [helpLabel]="'HEADER.Help' | translate" [profile]="'Profile.User' | translate"
		(onProfile)="router.navigate(['/profile'])" [logout]="'Profile.Logout' | translate " [profileName]="user?.toString() ?? ''"
    	[multicenter]="multicenter" [defaultCenter]="default_center" [centers]="centers" (centerChange)="onCenterChanged($event)"></mab-header>
		<mab-toast aria-live="polite" aria-atomic="true"></mab-toast>
		<mab-http-progress-bar></mab-http-progress-bar>
		<router-outlet></router-outlet>
	</div>
</div>

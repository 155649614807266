import { Component, Renderer2, ViewChild } from '@angular/core';
import { BaseComponent, Response, SessionService } from '@madrija/angular-base/common';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import {
  User, ProcessFilePointerLogService, MenuItemService, UserService, PropertyService, MenuItemDTOHelper, MenuItemDTO as MadrijaMenuItem,
  UserHelper, AuthenticationService, Center, InactivityService
} from '@madrija/base-ui/common';
import { HeaderComponent, HelpItem, Notification } from '@madrija/angular-base/layout';
import { AppConfig } from './config/app.config';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@madrija/angular-base/toast';
import { WaitingAppointmentService, OrderService } from '@madrija/medical-ui/common';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UserService, MenuItemService, ProcessFilePointerLogService, WaitingAppointmentService, PropertyService, OrderService, AuthenticationService]
})
export class AppComponent extends BaseComponent {
  title = 'cvis-ui';

  items: MenuItem[] = [];
  locale: string = 'en';
  version: string = '';
  languages: any[] = [];
  centers: Center[] = [];
  default_center: Center | null = null;
  user: User | null = null;
  loading = false;
  notifications: Notification[] = [];
  help: HelpItem[] | null = null;
  multicenter = false;
  @ViewChild('header', { static: false }) header: HeaderComponent | null = null;

  constructor(private appConfig: AppConfig, private translate: TranslateService, public override toastr: ToastService,
    private processLogService: ProcessFilePointerLogService, private waitingAppointmentService: WaitingAppointmentService,
    private itemsService: MenuItemService, private sessionService: SessionService,
    private userService: UserService, public override modalService: NgbModal, private inactivityService: InactivityService,
    private orderService: OrderService, private propertyService: PropertyService, public router: Router, private authenticationService: AuthenticationService,
    private primeNGConfig: PrimeNGConfig) {
    super(toastr, translate, modalService);
    translate.setDefaultLang('en');
    inactivityService.authenticationService = this.authenticationService;
    if (this.appConfig.loaded) {
      this.getInitialConfig();
    }
  }

  private getInitialConfig() {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.languages = this.appConfig.languages;
    this.createTechnicalLang();
    this.user = this.appConfig.user;
    this.centers = this.user?.allAvaiableCenters ? this.user.allAvaiableCenters : [];
    this.default_center = this.user?.default_center ? this.user.default_center : null;
    let locale = 'en';
    if (this.appConfig.user && this.appConfig.user.locale) {
      locale = this.appConfig.user.locale;
    }
    this.version = this.appConfig.version;
    setTimeout(() => {
      this.setLocale(locale).then(() => this.getNotifications());
    }, 200);
    this.getHelpItems();
    if (localStorage.getItem('MULTICENTER_ENABLED') === 'true') this.multicenter = true;
    if (localStorage.getItem('BETA') === 'true') setInterval(() => { this.getNotifications(['conflict-resolution']); }, 60000);
    setInterval(() => { this.getNotifications(['waitingList', 'etl-errors']); }, 900000);
  }

  public forzeLoadConfig() {
    this.appConfig.load(true).then((value: boolean) => { if (value) this.getInitialConfig() })
  }

  private getHelpItems() {
    this.propertyService.getAll('?search=key=BASE_HELP')
      .subscribe(
        response => {
          if (response && response.data && response.data[0] && response.data[0].largeValue) {
            const items = JSON.parse(response.data[0].largeValue);
            if (items && items.length > 0) {
              this.help = [];
              items.forEach((item: any) => {
                this.help?.push(item);
              });
            }
          }
        },
        () => { this.toastr.error(this.translate.instant('HEADER.ErrorHelpItems'), this.translate.instant('TEXT-EDITOR-TEMPLATE.ErrorTitle')) },
        () => {
          if (this.version !== '') if (!this.help) this.help = []; this.help?.push({ position: "version", icon: "", text: this.version, link: "", style: "" });
        }
      );
  }

  private createMenu() {
    this.itemsService.getAllByRole('').subscribe(
      response => {
        if (response.status >= 0) {
          const list = [];
          let list2 = [];
          for (const obj of response.data) {
            const item = MenuItemDTOHelper.buildFromJSON(obj);
            list.push(item);
          }
          this.markAsNotification(list);
          list2 = this.castToPrimeNgMenuItem(list);
          this.items = list2;
          this.moveMenuItemsToNotifications();
        }
      }
    );
  }

  private markAsNotification(items: MadrijaMenuItem[]) {
    const notificationDetector = ['admin', 'master'];
    const icons = { 'admin': 'fa-solid fa-wrench', 'master': 'fa-solid fa-table' };
    for (const iterator of notificationDetector) {
      const found = items.find((item: MadrijaMenuItem) => item.label?.toLocaleLowerCase().includes(iterator));
      if (found) {
        Reflect.set(found, 'notification', true);
        Reflect.set(found, 'icon', Reflect.get(icons, iterator));
      }
    }
  }

  private moveMenuItemsToNotifications() {
    this.notifications = this.notifications.filter((item) => item.id == 'waitingList' || item.id == 'etl-errors' || item.id == 'conflict-resolution');
    const copy: MenuItem[] = [];
    const notificationsCopy: Notification[] = [];
    this.items.forEach((element: MenuItem) => {
      if (Reflect.get(element, 'notification')) {
        notificationsCopy.push({
          icon: element.icon,
          items: element.items
        });
      } else {
        copy.push(element)
      }
    });
    this.notifications.unshift(...notificationsCopy);
    this.items = copy;
  }

  public onLocaleChanged(lang: string) {
    this.setLocale(lang);
    if (this.user) {
      this.user.locale = lang;
      this.userService.updateMySelf(this.user).subscribe(
        response => {
          if (response.status >= 0) {
            this.user = UserHelper.buildFromJSON(response.data);
          }
        }
      );
    }
  }
  public onCenterChanged(center: Center) {
    if (this.user) {
      this.user.default_center = center;
      this.userService.updateMySelf(this.user).subscribe(
        response => {
          if (response.status >= 0) {
            this.user = UserHelper.buildFromJSON(response.data);
            this.authenticationService.refreshToken().subscribe(
              response => {
                if (response.status >= 0) {
                  window.location.reload();
                }
              });
          } else {
            this.toastr.error(this.translate.instant('MULTICENTER.NotSaved'), this.translate.instant('MULTICENTER.Error'));
          }
        },
        error => {
          this.toastr.error(this.translate.instant('MULTICENTER.NotSaved'), this.translate.instant('MULTICENTER.Error'));
        }
      );
    }
  }
  private setLocale(locale: string): Promise<boolean> {
    return new Promise(resolve => {
      if (locale === 'tech') {
        this.translate.setDefaultLang(locale);
      }
      this.locale = (locale !== '') ? locale : 'en';
      this.translate.use(this.locale).subscribe(() => {
        this.getNotifications();
        this.createMenu();
        resolve(true);
      });
      this.translate.get('primeng').subscribe(resp => this.primeNGConfig.setTranslation(resp));
      this.sessionService.registerCulture(this.locale);
    })
  }

  private castToPrimeNgMenuItem(list: MadrijaMenuItem[]): MenuItem[] {
    const notificationsItems: MadrijaMenuItem[] = list.filter((value: MadrijaMenuItem) => Reflect.get(value, 'notification') as boolean);
    const items = MenuItemDTOHelper.castToPrimeNgMenuItem(list, this.translate, () => this.header?.deselectItems());
    notificationsItems.forEach((element: MadrijaMenuItem) => {
      const found = items.find((value: MenuItem) => value.id === String(element.pid));
      if (found) {
        Reflect.set(found, 'notification', true);
        found.icon = Reflect.get(element, 'icon');
      }
    });
    return items
  }
  public getNotifications(refresh?: string[]) {
    let menuInNotifications = this.notifications.filter((item) => item.id != 'waitingList' && item.id != 'etl-errors' && item.id != 'conflict-resolution');
    this.notifications = [];
    this.refreshNotifications(refresh);
    this.addNotification('waitingList', 'WAITINGAPPOINTMENT_READ', '/waiting-list', 'fa-calendar-plus', 'HEADER.WaitingList', this.waitingAppointmentService.getCount(), refresh);
    this.addNotification('etl-errors', 'LOGFILEPOINTER_READ', '/masters/base/etl-errors', 'fa-file-exclamation', 'HEADER.ETLErrors', this.processLogService.getAll('?search=active=true-B,solved=false-B'), refresh); //TODO: Create new endpoint only with count
    if (localStorage.getItem('BETA') === 'true') this.addNotification('conflict-resolution', '', '/views/crm/conflict-resolution', 'fa-user-question', 'CONFLICTS.UnidentifiedTransmissions', this.orderService.getConflictsCount(), refresh);
    this.notifications.unshift(...menuInNotifications);
  }

  private refreshNotifications(refresh?: string[]) {
    if (refresh) {
      for (const iterator of refresh) {
        if (this.notifications) {
          const foundIdx = this.notifications.findIndex((element: any) => element.id === iterator);
          this.notifications.splice(foundIdx, 1);
        }
      }
    }
  }

  private addNotification(id: string, action: string, link: string, icon: string, title: string, apiCall: Observable<Response>, refresh?: string[]) {
    if ((!action || this.isAllowed([action])) && (!refresh || refresh && refresh.includes(id))) {
      this.notifications.push({ id: id, link: link, icon: icon, count: 0, title: this.translate.instant(title) });
      apiCall.subscribe(
        (response: any) => {
          if (response.status >= 0) {
            const array: [] = response.data;
            this.setCount(id, (id === 'waitingList' || id === 'conflict-resolution') ? response.data : array.length);
          }
        }
      );
    }
  }

  public setCount(id: string, count: number) {
    const find = this.notifications.find((notification: Notification) => notification.id === id);
    if (find) find.count = count;
  }

  private createTechnicalLang() {
    if (this.languages && localStorage.getItem('BASE_TECHNICAL_LANG') === 'true') {
      this.languages.push({ code: 'tech', flag: 'assets/images/tech.svg' });
    }
  }

  logout() {
    this.deleteSessionData();
    this.inactivityService.doLogout();
  }

  deleteSessionData() {
    localStorage.removeItem("MED_SESSION_SCHEDULE");
  }

  get shouldAppearHeader() {
    const childRoute = this.router['rootContexts'].contexts.get('primary').route;
    const childRouteSnapshot = childRoute?.snapshot;
    const data = (childRouteSnapshot) ? childRouteSnapshot.data : null;
    return data && !data['noHeader'];
  }

  get isLoaded(): boolean {
    return this.appConfig.loaded;
  }
}
